@function unicode($str) {
    @return unquote("\"") + unquote(str-insert($str, "\\", 1)) + unquote("\"");
}

@include icomoon('icomoon', '../fonts/icomoon', (
    arrow: unicode("e900"),
    arrow1: unicode("e901"),
    chat: unicode("e902"),
    copy: unicode("e903"),
    facebook: unicode("e904"),
    google: unicode("e905"),
    heart-full: unicode("e906"),
    heart: unicode("e907"),
    instagram: unicode("e908"),
    kariera: unicode("e909"),
    linkedin: unicode("e90a"),
    play: unicode("e90b"),
    share: unicode("e90c"),
    zoznam: unicode("e90d"),
    eye: unicode("e90e"),
    close: unicode("e90f"),
    hamburger: unicode("e910"),
    work: unicode("e917"),
    user: unicode("e916"),
    plus: unicode("e915"),
    mail: unicode("e914"),
    list: unicode("e913"),
    home: unicode("e912"),
    euro: unicode("e911"),
    check: unicode("e918"),
    calendar: unicode("e919"),
    swipe: unicode("e91a"),
    A: unicode("e91b"),
    A1: unicode("e91c"),
    A2: unicode("e91d"),
    AM: unicode("e91e"),
    B: unicode("e91f"),
    B1: unicode("e920"),
    BE: unicode("e921"),
    C: unicode("e922"),
    C1: unicode("e923"),
    C1E: unicode("e924"),
    CE: unicode("e925"),
    D: unicode("e926"),
    D1: unicode("e927"),
    D1E: unicode("e928"),
    DE: unicode("e929"),
    T: unicode("e92a"),
    rotate: unicode("e92b"),
    magnifier: unicode("e92c"),
    info: unicode("e92d"),
    sortable: unicode("e92e"),
    youtube: unicode("e92f"),
    cardpay: unicode("e931"),
    tatrapay: unicode("e930")
))
