.box-share {
    margin: 0 0 60px 0;
    
    @include respond-above(md) {
        margin: 0 0 80px 0;    
    }

    .container {
        display: flex;
        justify-content: space-between;
         align-items: center;

        .icon-zoznam {
            color: $base-font-color;
            font-size: $base-font-size + 6;
            text-decoration: none;
        }
    }
}
