.box-advertisement {
    margin: 0 -10px 60px -10px;
    width: calc(100% + 20px);
    
    @include respond-above(xsm) {
        margin: 0 0 60px 0; 
        width: auto;
    }
    
    @include respond-above(md) {
        margin: 0 0 80px 0;
    }

    .container {
        text-align: center;
    }
}
