.box-paid-offer {
    max-width: 800px;
    width: calc(100% - 40px);
    position: fixed;
    margin: 0 20px;
    left: 50%;
    transform: translateX(calc(-50% - 20px));
    bottom: 20px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: $base-border-radius;
    box-shadow: 0px 13px 33px #00000024;
    z-index: $base-z-index + 15;
    padding: 15px 15px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);


    @include respond-above(sm) {
        padding: 22px 24px;
        display: flex;
        align-items: center;
    }

    .icon-close {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: $base-font-size - 2;
        margin: 0;
        padding: 0;
        border: 0;
        background: transparent;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: $base-line-height + 10;
    }

    .box-paid-offer-logo {
        display: none;

        a {
            display: block;
            margin-right: 25px;

            img {
                width: 50px;
            }
        }

        @include respond-above(sm) {
            display: block;
        }
    }

    .box-paid-offer-content {
        width: auto;

        @include respond-above(sm) {
            width: calc(100% - 75px);
        }

        .box-paid-offer-label {
            font-size: $base-font-size - 2;
            line-height: $base-line-height - 4;
            margin: 0 0 6px 0;
            font-weight: $font-weight-medium;
            max-width: calc(100% - 40px);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            @include respond-above(sm) {
                font-size: $base-font-size;    
            }

        }

        .box-paid-offer-title {
            font-size: $base-font-size + 2;
            line-height: $base-line-height - 2;
            font-weight: $font-weight-medium;
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;

            @include respond-above(sm) {
                width: calc(100% - 155px);
            }
        }

        .box-paid-offer-info {


            @include respond-above(sm) {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
            }

            .box-paid-offer-info-left {

                margin: 0;
                padding: 0;
                list-style-type: none;

                @include respond-above(sm) {
                    width: calc(100% - 155px);
                    display: flex;
                }

                li {
                    font-size: $base-font-size - 2;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    min-width: 80px;
                    max-width: 340px;
                    margin: 0;
                    
                    @include respond-above(sm) {
                        margin: 0 24px 0 0;
                        font-size: $base-font-size;
                    }

                    &:last-child {
                        margin: 0;
                    }

                    img {
                        width: 10px;
                        display: inline-block;
                        margin: -2px 8px 0 0;
                        vertical-align: middle;
                        
                        @include respond-above(sm) {
                            width: 15px;
                        }
                    }
                }
            }

            .box-paid-offer-info-right {
                width: 155px;
                text-align: right;

                .btn {
                    display: none;

                    @include respond-above(sm) {
                        display: inline-block;
                    }
                }

            }
        }
    }
}
