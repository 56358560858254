.header {
    background-color: $base-color;
    color: $white-color;
    left: 0;
    top: -60px;
    width: 100%;
    z-index: $base-z-index + 20;
    @include transition-with-prefix('top', '200ms');

    @include respond-above(lg) {
        top: -76px;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 16px;
        padding-bottom: 16px;
        height: 60px;

        @include respond-above(lg) {
            padding-top: 18px;
            padding-bottom: 18px;
            height: 76px;
        }

        .header-left {
            .header-logo {
                color: $white-color;
                display: block;
                height: 28px;
                line-height: $base-line-height + 8;
                font-size: $base-font-size + 14;

                &::before {
                    content: "\e909";
                    font-family: "icomoon";
                }

                &:hover {
                    text-decoration: none;
                }

                @include respond-above(md) {
                    height: 28px;
                    line-height: $base-line-height + 8;
                    font-size: $base-font-size + 14;
                }

                @include respond-above(lg) {
                    height: 34px;
                    line-height: $base-line-height + 14;
                    font-size: $base-font-size + 20;
                }
            }
        }

        .header-menu {
            display: none;

            ul {
                display: flex;
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    margin: 0 20px;


                    a {
                        color: #B3EADB;

                        &.active {
                            color: #B3EADB;
                        }
                    }


                    a {
                        color: $white-color;
                        font-weight: $font-weight-medium;
                    }
                }
            }

            @include respond-above(lg) {
                display: block;
            }
        }

        .header-right {
            .header-login-buttons {
                display: none;
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    margin-right: 20px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @include respond-above(lg) {
                    display: flex;
                }
            }

            .header-menu-btn {
                margin: 0;
                padding: 0;
                border: 0;
                background: transparent;
                color: $white-color;
                cursor: pointer;
                font-size: $base-font-size + 2;

                &::before {
                    content: "\e910";
                    font-family: "icomoon";
                }

                @include respond-above(lg) {
                    display: none;
                }
            }

            .header-user-info {
                position: relative;
                display: none;

                &:hover {
                    cursor: pointer;

                    .header-user-submenu {
                        display: block;
                    }
                }

                @include respond-above(lg) {
                    display: block;
                }

                .header-user-btn {
                    margin: 0;
                    padding: 6px 0 6px 0;
                    border: 0;
                    display: block;
                    background-color: transparent;
                    color: $white-color;

                    &:hover {
                        .header-user-name {
                            text-decoration: underline;
                        }
                    }


                    .header-user-name {
                        display: inline-block;
                        vertical-align: middle;
                        font-weight: $font-weight-medium;

                        .header-user-company-info {
                            font-weight: $font-weight-normal;
                        }
                    }

                    .header-user-avatar {
                        font-size: $base-font-size;
                        font-weight: $font-weight-medium;
                        height: 40px;
                        width: 40px;
                        line-height: 40px;
                        border-radius: 50%;
                        color: $base-color;
                        background-color: $white-color;
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 10px;
                        text-align: center;
                    }

                    .header-user-image {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        vertical-align: middle;
                        margin-left: 10px;
                    }

                    &:hover {
                        text-decoration: underline;
                    }

                }

            }
        }
    }
}
