.box-agent {
    background-color: $base-color;
    color: $white-color;
    border-radius: $base-border-radius;
    color: $white-color;
    padding: 20px 100px 20px 20px;
    position: relative;
    
    
    @include respond-above(md) {
        padding: 30px 105px 30px 30px;    
        
    }
    
    &::after {
        position: absolute;
        right: -12px;
        top: calc(50% - 88px);
        width: 101px;
        height: 175px;
        content: "";
        @include background-2x( '../assets/avatar4', 'png', auto, 100%, 0 0, no-repeat );
    }
    
    .box-agent-title {
        font-size: $base-font-size + 4;
        line-height: $base-line-height + 1;
        font-weight: $font-weight-medium;
        margin: 0 0 15px 0;
        
        @include respond-above(md) {
            font-size: $base-font-size + 6;
            line-height: $base-line-height + 4;        
        }
    }
    .box-agent-desc {
        margin: 0 0 30px 0;
        font-size: $base-font-size;
        line-height: $base-line-height;
    }
    
    .icon-close {
        position: absolute;
        right: 15px;
        top: 15px;
        border: 0;
        margin: 0;
        padding: 0;
        color: $white-color;
        font-size: $base-font-size - 2;
        background-color: transparent;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
    }
}
