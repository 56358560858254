.box-my-offers {
    margin: 0 auto 40px auto;
    max-width: 1220px;
    width: 100%;

    .box-my-offers-title {
        font-size: $base-font-size + 6;
        line-height: $base-line-height + 4;
        font-weight: $font-weight-medium;
        margin: 0;
        padding: 0 20px;
        
        @include respond-above(xl) {
            padding: 0 40px;    
        }
    }

    .box-my-offers-slider {
        padding: 40px 20px;
        margin: -20px 0 0 0;
        
        @include respond-above(xl) {
            padding: 40px;    
        }

        .offer {
            border: 1px solid $base-border-color;
            border-radius: $base-border-radius;
            padding: 20px;
            display: block;

            .offer-title {
                font-weight: $font-weight-medium;
                font-size: $base-font-size;
                line-height: $base-line-height - 2;
                font-weight: $font-weight-medium;
                color: $base-font-color;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:hover {
                text-decoration: none;
                border-color: $white-color;
                box-shadow: 0px 13px 33px #00000024;
            }
        }

        .offer-employer {
            margin: 0 0 20px 0;
            line-height: $base-line-height - 2;
            font-size: $base-font-size - 1;
            color: $base-font-color;
        }

        .offer-more {
            i {
                font-size: $base-font-size - 4;
                display: inline-block;
                vertical-align: middle;
                margin: -3px 0 0 0;
                padding: 0 10px 0 0;
                transform: rotate(180deg);
            }
        }

        .swiper-pagination {

            .swiper-pagination-bullet {
                background-color: $base-font-color;
            }
        }
    }
}
