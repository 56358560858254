.box-region {
    margin-bottom: 60px;
    
    @include respond-above(md) {
        margin-bottom: 80px;
    }

    .box-region-list {
        padding: 0;
        margin: 0 0 60px 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        
        @include respond-above(md) {
            margin: 0 0 30px 0;    
        }
        
        @include respond-above(lg) {
            margin: 0;    
        }

        li {
            margin: 0 8px 8px 0;
            
            @include respond-above(md) {
                margin: 0 10px 10px 0;    
            }

            a {
                display: block;
                padding: 12px 19px;
                border: 1px solid $base-border-color;
                border-radius: $base-border-radius;
                color: $base-font-color;
                font-size: $base-font-size;
                
                @include respond-above(md) {
                    padding: 13px 19px; 
                    font-size: $base-font-size;
                }

                &:hover {
                    text-decoration: none;
                    box-shadow: 0px 13px 33px #00000024;
                    border-color: $white-color;
                }
            }
        }
    }
}
