.cv-list-content {
    position: relative;
    h1 {
        font-size: $base-font-size + 6;
        line-height: $base-line-height + 4;
        font-weight: $font-weight-medium;
        margin: 0 0 8px 0;

        @include respond-above(md) {
            font-size: $base-font-size + 10;
            line-height: $base-line-height + 8;
        }

        sup {
            color: $grey-color;
            font-size: $base-font-size;
            line-height: $base-line-height - 4;
            font-weight: $font-weight-normal;

            @include respond-above(md) {
                font-size: $base-font-size + 2;
                line-height: $base-line-height - 2;
            }
        }
    }

    .breadcrumbs {
        ul {
            padding: 0;
            margin: 0 0 5px 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;

            li {
                font-size: $base-font-size - 2;
                color: $grey-color;
                line-height: $base-line-height - 7;

                &::after {
                    content: "/";
                    color: $base-font-color;
                    margin: 0 4px;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }

                a {
                    color: $grey-color;
                }
            }

            &:last-child {
                margin: 0 0 24px 0;
            }
        }
    }

    .cv-list-message {
        font-size: $base-font-size + 4;
        line-height: $base-line-height + 1;
        font-weight: $font-weight-medium;
        padding: 0 0 15px 0;
        border-bottom: 1px solid $base-border-color;
        margin: 0 0 15px 0;
        text-align: center;

        @include respond-above(sm) {
            font-size: $base-font-size + 6;
            line-height: $base-line-height + 4;
            padding: 0 0 24px 0;
            margin: 0 0 15px 0;
        }
    }

    .cv-list {

        .cv {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid $base-border-color;
            margin-bottom: 25px;

            .cv-content {
                width: calc(100% - 100px);
                margin: 0 20px 0 0;

                .cv-person {
                    margin: 0 0 15px 0;

                    a {
                        font-size: $base-font-size + 6;
                        line-height: $base-line-height + 4;
                        font-weight: $font-weight-medium;
                    }

                    i {
                        display: inline-block;
                        width: 23px;
                        height: 23px;
                        text-align: center;
                        line-height: 23px;
                        border-radius: 50%;
                        font-size: $base-font-size - 5;
                        vertical-align: top;
                        margin: 0 0 0 6px;


                        &.icon-eye {
                            background-color: $yellow-color;
                        }

                        &.icon-check {
                            background-color: $base-color;
                            color: $white-color;
                        }
                    }
                }

                .cv-info {
                    font-size: $base-font-size;
                    line-height: $base-line-height - 4;
                    margin: 0 0 6px 0;

                    span {
                        color: $grey-color;
                    }
                }

                .cv-date {
                    font-size: $base-font-size - 2;
                    line-height: $base-line-height + 2;
                    color: $grey-color;
                    margin: 0 0 24px 0;
                    padding: 9px 0 0 0;
                }

            }

            .circle-progress-bar {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80px;
                height: 80px;
                border-radius: 50%;

                .circle-progress-bar-info {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;

                    .circle-progress-bar-percentage {
                        margin: 0;
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 16px;
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        border: 8px solid #e5e5e5;
                    }

                    .circle-progress-bar-title {
                        font-size: 11px;
                        line-height: 12px;
                    }
                }

                .circle-progress-bar-svg {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    fill: none;
                    transform: rotate(-90deg);
                }

                .circle-progress-bar-circle {
                    stroke: #00b787;
                    stroke-width: 8px;
                    stroke-linecap: round;
                    stroke-dasharray: 226;
                    stroke-dashoffset: 226;
                    animation-duration: 2s;
                    animation-timing-function: linear;
                    animation-fill-mode: forwards;
                    transition: 2s stroke-dashoffset;
                }
            }
        }
    }
}
