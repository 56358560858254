.listing-exports {
    .exports {
        .export {
            border-bottom: 1px solid $base-border-color;
            display: flex;
            justify-content: space-between;
            padding: 10px 0 10px 0;
            align-items: center;
            .export-name {
                font-weight: $font-weight-medium;
            }
            .export-files {
                white-space: nowrap;
                padding: 0 0 0 10px;
                .btn {
                    margin: 0 0 0 10px;
                }
            }
        }
    }
}