.box-cv {
    background-color: $yellow-color;
    padding: 20px;
    text-align: center;
    margin: 0 0 24px 0;

    .box-cv-title {
        font-size: $base-font-size + 4;
        line-height: $base-line-height + 1;
        font-weight: $font-weight-medium;
        margin: 0 0 15px 0;

        @include respond-above(md) {
            font-size: $base-font-size + 6;
            line-height: $base-line-height + 4;
        }
    }

    .box-cv-desc {
        margin: 0 0 30px 0;
        font-size: $base-font-size;
        line-height: $base-line-height;
    }
    
    .btn {
        background-color: $base-font-color;
        color: $yellow-color;
        border-color: $base-font-color;
        
        &:hover {
            background-color: #000000;
            border-color: #000000;
            color: $yellow-color;
        }
    }
}
