.box-offer-list-locality {
    h2 {
        text-align: left !important;
        margin-bottom: 24px !important;
    }

    ul {

        padding: 0;
        list-style-type: none;


        @include respond-above(sm) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
        }

        li {


            @include respond-above(sm) {
                width: calc(50% - 20px);
                margin: 0 10px;
            }

            a {
                color: #000;
                text-decoration: none;

                &:hover {
                    color: $base-color;
                }
            }
        }
    }
}
