.box-article-slider-wrapper {
    margin: 0 0 60px 0;
    overflow: hidden;

    @include respond-above(md) {
        margin: 0 0 80px 0;
    }

    .box-article-slider-desc {
        margin: 0 0 20px 0;

        @include respond-above(md) {
            margin: 0 0 30px 0;
        }

        p {
            padding: 0 0 10px 0;
        }
    }

    .box-article-slider-overflow-wrapper {
        width: 100vw;
        
        @include respond-above(md) {
            width: 70vw;     
        }

        .box-article-slider {

            .swiper-slide {
                width: 207px;

                @include respond-above(md) {
                    width: 267px;
                }

                .box-article {
                    display: block;
                    border-radius: $base-border-radius;
                    color: $base-font-color;

                    img {
                        border-radius: $base-border-radius;
                        margin: 0 0 10px 0;
                        display: block;
                        width: 100%;
                        max-width: 100%;

                        @include respond-above(md) {
                            margin: 0 0 15px 0;
                        }
                    }

                    span {
                        display: block;
                        font-size: $base-font-size + 4;
                        line-height: $base-line-height + 1;
                        padding: 0 10px;
                        font-weight: $font-weight-medium;

                        @include respond-above(md) {
                            font-size: $base-font-size + 6;
                            line-height: $base-line-height + 4;
                        }
                    }
                }
            }
        }
    }

    .box-article-slider-pagination {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 20px 0;

        @include respond-above(md) {
            margin: 0;
        }

        .box-article-slider-page {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0 15px 0 0;

            .box-article-slider-page-number {
                line-height: $base-line-height + 2;
                font-size: $base-font-size;
                color: #b7b7b7;
                cursor: pointer;
            }

            .box-article-slider-indicator {
                position: relative;
                width: 48px;
                height: 3px;
                margin: 0 0 0 15px;
                display: none;
                background-color: $base-border-color;
                border-radius: $base-border-radius;

                .box-article-slider-indicator-state {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0%;
                    height: 3px;
                    background-color: $base-color;
                    border-radius: $base-border-radius;
                }
            }

            &.box-article-slider-page-active {

                .box-article-slider-page-number {
                    color: $base-font-color;
                }

                .box-article-slider-indicator {
                    display: block;

                    .box-article-slider-indicator-state {
                        animation-name: countingBar;
                        animation-duration: 3s;
                        animation-timing-function: ease-in;
                        animation-iteration-count: 1;
                        animation-direction: alternate;
                        animation-fill-mode: forwards;
                    }
                }
            }
        }
    }

}

@keyframes countingBar {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
