.modal-wrapper {
    background-color: rgba(43, 43, 43, 0.6);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: $base-z-index + 40;
    display: flex;
    justify-content: center;
    align-items: center;


    &.modal-cv {
        flex-direction: column;

        .modal-close {
            background-color: $yellow-color;
            padding: 14px 16px;
            border: 0;
            font-size: $base-font-size;
            font-weight: $font-weight-medium;
            margin: 0 0 20px 0;
            border-radius: $base-border-radius;
            @include transition-with-prefix('background-color', '200ms');

            i {
                font-size: $base-font-size - 4;
                display: inline-block;
                margin: -3px 10px 0 0;
                vertical-align: middle;
            }


            &:hover {
                background-color: $yellow-hover-color;
            }
        }

        .modal {
            max-width: 750px;

            padding: 20px 0 0 0;

            @include respond-above(md) {
                padding: 40px 0 0 0;
            }

            .modal-scroll-content {
                padding: 0 20px;

                @include respond-above(md) {
                    padding: 0 40px;
                }

                .modal-content {
                    padding: 0;

                    .modal-cv-header {

                        justify-content: space-between;


                        @include respond-above(md) {
                            display: flex;
                            flex-wrap: wrap;
                        }

                        .modal-cv-name {
                            font-size: $base-font-size + 4;
                            line-height: $base-line-height + 1;
                            margin: 0 0 20px 0;

                            @include respond-above(md) {
                                margin: 0;
                                width: 30%;
                                padding: 0 20px 0 0;
                            }

                            strong {
                                display: block;
                                font-size: $base-font-size + 14;
                                line-height: $base-line-height + 12;
                                font-weight: $font-weight-medium;
                            }
                        }

                        .modal-cv-info {
                            margin: 0;
                            padding: 0;
                            list-style-type: none;

                            @include respond-above(md) {
                                padding: 0 20px 0 0;
                                width: 40%;
                            }

                            li {
                                padding: 0 0 0 28px;
                                position: relative;
                                margin: 0 0 15px 0;

                                a {
                                    color: $base-font-color;
                                }

                                img {
                                    width: 20px;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                }
                            }
                        }

                        .modal-cv-image {


                            @include respond-above(md) {
                                width: 30%;
                                text-align: right;

                            }

                            img {
                                display: inline-block;
                                width: 100%;
                                max-width: 180px;
                                border-radius: 50%;
                                aspect-ratio: 1 / 1;
                                object-fit: cover;
                            }

                        }

                        .modal-cv-desc {
                            width: 100%;
                            padding: 20px 0 0 0;
                            margin: 0 0 40px 0;
                        }
                    }

                    .modal-cv-block {

                        padding: 0 0 20px 0;

                        @include respond-above(md) {
                            display: flex;
                            flex-wrap: wrap;
                        }

                        .modal-cv-title {
                            position: relative;
                            margin: 0 0 30px 0;
                            width: 100%;

                            &::after {
                                border-bottom: 1px solid $base-border-color;
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 11px;
                                width: 100%;
                                z-index: 2;
                            }

                            span {
                                padding: 0 10px 0 0;
                                font-size: $base-font-size + 4;
                                line-height: 21px;
                                color: $base-color;
                                font-weight: $font-weight-medium;
                                display: inline-block;
                                background-color: $white-color;
                                position: relative;
                                z-index: 3;

                            }
                        }

                        .modal-cv-subtitle {
                            width: 100%;
                            font-size: $base-font-size + 2;
                            line-height: $base-line-height;
                            font-weight: $font-weight-medium;
                            margin: 0 0 20px 0;
                        }

                        .modal-cv-left {

                            padding: 0 0 16px 0;

                            @include respond-above(md) {
                                width: 39%;
                                padding: 0 0 16px 20px;
                            }

                            strong {
                                display: block;
                                font-size: $base-font-size + 4;
                                line-height: $base-line-height + 1;
                                margin: 0 0 4px 0;
                            }

                            .date {
                                font-size: $base-font-size - 2;
                                line-height: $base-line-height - 7;
                            }
                        }

                        .modal-cv-right {

                            padding: 0 0 16px 0;

                            @include respond-above(md) {
                                width: 61%;
                            }

                            i[class^='icon'] {
                                margin-right: 10px;
                            }

                            .cv-progress-bar {
                                max-width: 300px;
                                margin-top: 4px;

                                &.level {
                                    .progress-bar {
                                        margin: 0 5px 0 5px;
                                    }
                                }

                                &.with-percentage {
                                    margin-bottom: 0;
                                    margin-top: 0;
                                }
                            }
                        }

                        .modal-cv-fullwidth {
                            width: 100%;
                            padding: 0 0 16px 0;

                            @include respond-above(md) {
                                padding: 0 0 16px 20px;
                            }
                        }
                    }
                }
            }

        }
    }

    .modal {
        background-color: $white-color;
        border-radius: $base-border-radius;
        width: 100%;
        max-width: 400px;
        max-height: 100%;

        &.modal-wide {
            max-width: 500px;
        }

        &.modal-advertising {
            .modal-header {
                background-color: $base-color;
                border-top-left-radius: $base-border-radius;
                border-top-right-radius: $base-border-radius;
                padding: 56px 30px 60px 30px;
                display: block;
                position: relative;

                .modal-header-title {
                    color: $white-color;
                    white-space: normal;
                    font-size: $base-font-size + 8;
                    line-height: $base-line-height + 6;
                    text-align: center;
                    max-width: none;
                }

                .icon-close {
                    position: absolute;
                    right: 30px;
                    top: 30px;
                    color: $white-color;
                }
            }

            .modal-scroll-content {
                overflow-y: visible;

                .modal-content {
                    text-align: center;

                    .modal-top-image {
                        max-width: 234px;
                        margin: -50px auto 20px auto;
                        display: block;
                        position: relative;
                    }

                    h2 {
                        font-size: $base-font-size + 4;
                        line-height: $base-line-height + 1;
                        margin: 0 0 20px 0;
                    }

                    .btn {
                        padding: 10px 14px;
                        min-width: 170px;
                    }
                }
            }
        }

        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 0 0 25px 0;
            padding: 20px 20px 0 20px;

            @include respond-above(md) {
                padding: 30px 30px 0 30px;
            }

            .modal-header-title {
                font-size: $base-font-size + 6;
                line-height: $base-line-height + 8;
                font-weight: $font-weight-medium;
                max-width: calc(100% - 40px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                @include respond-above(md) {
                    font-size: $base-font-size + 10;
                }
            }
            
            .modal-header-title-small {
                font-size: $base-font-size + 6;
                line-height: $base-line-height + 4;
                font-weight: $font-weight-medium;
                max-width: calc(100% - 40px);
            }

            .icon-close {
                font-size: $base-font-size + 2;
                margin: 0;
                padding: 0;
                border: 0;
                background: transparent;
            }
        }

        .modal-scroll-content {
            overscroll-behavior: contain;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            scrollbar-color: #e5e5e5 $white-color;
            padding: 0 20px;

            @include respond-above(md) {
                padding: 0 30px;
            }

            &::-webkit-scrollbar {
                width: 11px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
                width: 11px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #e5e5e5;
                border-right: 8px solid $white-color;
                border-top: 20px solid $white-color;
                border-bottom: 20px solid $white-color;
                border-radius: $base-border-radius;

                &:hover {
                    background-color: #e5e5e5;
                }

                &:active {
                    background-color: #e5e5e5;
                }

                &:vertical {
                    min-height: 175px;
                }
            }

            .modal-content {
                padding: 0 0 20px 0;
                
                .cv-image-preview {
                    cursor: pointer;
                }

                #zoom-area, .cv-image-preview {
                    width: 230px;
                    height: 230px;
                    overflow: hidden;
                    margin: 0 auto 20px auto;
                    position: relative;
                    display: block;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: $base-z-index;
                        @include background-2x('../assets/image_shape', 'png', auto, 100%, 0 0, no-repeat);
                    }

                    img {
                        width: 100%;
                        display: block;
                        aspect-ratio: 1 / 1;
                        object-fit: cover;
                    }
                }

                #zoomer {
                    -webkit-appearance: none;
                    appearance: none;
                    background: transparent;
                    cursor: pointer;
                    width: 100%;

                    &:focus {
                        outline: none;
                    }

                    &::-webkit-slider-runnable-track {
                        background-color: $base-border-color;
                        border-radius: $base-border-radius;
                        height: 6px;
                    }

                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        appearance: none;
                        margin-top: -9px;
                        background-color: $base-color;
                        border-radius: 50%;
                        height: 24px;
                        width: 24px;
                    }

                    &::-moz-range-track {
                        background-color: $base-border-color;
                        border-radius: 8px;
                        height: 6px;
                    }

                    &::-moz-range-thumb {
                        background-color: $base-color;
                        border: none;
                        border-radius: 50%;
                        height: 24px;
                        width: 24px;
                    }
                }

                .zoom-image-controls {
                    display: flex;
                    align-items: center;
                    margin: 0 auto 30px auto;
                    width: 260px;

                    .zoom-image-controls-left {
                        width: 65px;
                    }

                    .zoom-image-controls-center {
                        width: calc(100% - 105px);
                        padding-right: 20px;
                    }

                    .zoom-image-controls-right {
                        width: 40px;
                        border-left: 1px solid $base-border-color;
                        text-align: right;
                    }

                    .icon-rotate {
                        margin: 0;
                        padding: 0;
                        border: 0;
                        background-color: $white-color;
                        font-size: $base-font-size + 10;
                    }
                }
                
                .cv-image-delete {
                    text-align: center;
                    margin: 0 0 30px 0;
                    button {
                        margin: 0;
                        padding: 0;
                        border: 0;
                        background-color: $white-color;
                        color: $red-color;
                    }
                }

                .modal-controls {
                    display: flex;
                    justify-content: flex-end;

                    .modal-close {
                        margin: 0 20px 0 0;
                        padding: 0 10px;
                        border: 0;
                        height: 48px;
                        line-height: 48px;
                        font-size: $base-font-size;
                        background-color: $white-color;
                    }

                    .form-element {
                        margin: 0;
                    }

                }

                @include respond-above(md) {
                    padding: 0 0 30px 0;
                }

                .login-options {
                    text-align: center;
                    margin-top: -5px;

                    ul {
                        border-bottom: 1px solid $base-border-color;
                        margin: 0 0 20px 0;
                        padding: 0 0 20px 0;
                    }
                }

                .share-options {
                    list-style-type: none;
                    margin: 0 0 0 0;
                    padding: 0 0 10px 0;

                    li {
                        display: flex;
                        justify-content: space-between;
                        margin: 0 0 15px 0;
                        align-items: baseline;

                        a {
                            font-size: $base-font-size;
                            border: 1px solid $base-border-color;
                            padding: 0 10px;
                            height: 36px;
                            line-height: 36px;
                            display: block;
                            color: $base-font-color;
                            border-radius: $base-border-radius;

                            &:hover {
                                border-color: #fff;
                                -webkit-box-shadow: 0px 13px 33px rgb(0 0 0 / 14%);
                                box-shadow: 0px 13px 33px rgb(0 0 0 / 14%);
                                text-decoration: none;
                            }

                            i {
                                display: inline-block;
                                vertical-align: middle;
                                font-size: $base-font-size;
                                margin-right: 10px;
                                margin-top: -4px;
                            }
                        }
                    }
                }

                .offer-like {
                    p {
                        font-size: $base-line-height;
                        font-size: $base-font-size;
                        margin: 0 0 30px 0;
                    }

                    ul {
                        padding: 0;
                        margin: 0;
                        list-style-type: none;

                        li {
                            display: flex;
                            justify-content: space-between;
                            align-items: baseline;
                            margin: 0 0 15px 0;

                            &:last-child {
                                margin: 0;
                            }

                            .offer-like-left {
                                span {
                                    color: $grey-color;
                                }
                            }

                            .bg-yellow {
                                display: inline-block;
                                padding: 6px 14px;
                                font-size: $base-font-size;
                                font-weight: $font-weight-medium;
                                border-radius: $base-border-radius;
                                border-width: 1px;
                                border-style: solid;
                                line-height: 20px;
                                background-color: $yellow-color;
                                color: $base-font-color;
                                border-color: $yellow-color;
                            }
                        }

                    }
                }

                h2 {
                    font-size: $base-font-size + 6;
                    line-height: $base-line-height + 4;
                    margin: 0 0 20px 0;
                }

                .modal-agreement {
                    font-size: $base-font-size - 4;
                    line-height: $base-line-height - 9;
                    text-align: center;
                }
            }
        }
    }
}
