/* BASE BOOTSTRAP CSS VARIABLES OVERRRIDE */

:root,
[data-bs-theme=light] {
    --bs-body-font-family: #{$font-family};
    --bs-body-line-height: #{$base-line-height};
    --bs-body-color: #{$base-font-color};
}

@include respond-above(xs) {

    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@include respond-above(xl) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1164px;
        padding-left: 12px;
        padding-right: 12px;
    }
}

/* MAIN CONTENT */

html {
    overscroll-behavior: none;
}

body {
    font-size: $base-font-size;
    -webkit-font-smoothing: antialiased;

    .mobile-hide {
        display: none !important;

        @include respond-above(md) {
            display: block !important;
        }
    }

    .fixed-element {
        position: fixed;
        bottom: 20px;
        z-index: $base-z-index + 10;
    }

    &.menu-bottom-active {
        .fixed-element {
            bottom: 80px;

            @include respond-above(md) {
                bottom: 20px;
            }
        }

        .copyright {
            margin: 0 0 60px 0;

            @include respond-above(md) {
                margin: 0;
            }
        }

        .main {
            .main-content-center {
                .content-text {
                    &.article-detail {
                        .article-detail-float-button {
                            &.fixed {
                                bottom: 70px;

                                @include respond-above(md) {
                                    bottom: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.menu-active {
        overflow: hidden;

        .body-overlay {
            width: 100%;
            height: 100%;
            background: rgba(112, 112, 112, 0.6);
            position: fixed;
            left: 0;
            top: 0;
            z-index: $base-z-index + 20;
        }
    }

    &.modal-active {
        overflow: hidden;
    }

    &.has-fixed-header {
        .header {
            position: fixed;
            top: 0;
        }

        .main {
            padding: 60px 0 0 0;

            @include respond-above(lg) {
                padding: 76px 0 0 0;
            }
        }

    }

    &.page-salary {
        .main {
            .container {
                .main-content-center {
                    .content-text {
                        margin: 0 0 40px 0;
                    }

                    .box-white-form-content {
                        p {
                            margin: 0;
                            text-align: center;
                            font-size: $base-font-size + 10;
                            line-height: $base-line-height + 18;

                            strong {
                                font-size: $base-font-size + 14;
                                display: block;
                                font-weight: $font-weight-medium;
                            }
                        }
                    }

                    form {
                        padding: 0 0 20px 0;
                    }

                    .box-offer-list-locality h2 {
                        text-align: center !important;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .header-user-submenu {
        position: absolute;
        right: 0;
        top: 52px;
        background-color: $white-color;
        border-radius: $base-border-radius;
        padding: 24px;
        z-index: $base-z-index + 20;
        box-shadow: 0px 13px 33px #00000024;
        display: none;
        width: 260px;
    }

    .aside-user-submenu {
        padding: 0;

        li {
            &.has-submenu {
                &.last {
                    border-bottom: 0 !important;
                    padding: 0 !important;
                }

                button {
                    font-size: $base-font-size + 2 !important;
                }

                &.opened {
                    border-bottom: 1px solid $base-border-color;
                    padding: 0 0 10px 0;

                }
            }
        }
    }

    .header-user-submenu,
    .aside-user-submenu {
        margin: 0;
        list-style-type: none;

        li {
            margin: 0 0 16px 0;

            &.active {
                a {
                    color: $grey-color;
                }
            }

            a {
                line-height: $base-line-height - 4;
                font-size: $base-font-size;
                color: $base-font-color;
                font-weight: $font-weight-medium;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .number {
                    display: inline-block;
                    font-size: $base-font-size - 3;
                    height: 16px;
                    line-height: $base-line-height - 4;
                    background-color: $base-color;
                    color: $white-color;
                    border-radius: 100px;
                    padding: 0 7px;
                    vertical-align: middle;
                    margin: 0;
                    float: right;
                }
            }

            &.logout {
                border-top: 1px solid $base-border-color;
                margin-bottom: 0;
                padding-top: 16px;

                a {
                    font-weight: $font-weight-normal;
                    color: $red-color;
                }
            }

            &.has-submenu {
                button {
                    margin: 0 0 16px 0;
                    padding: 0 20px 0 0;
                    border: 0;
                    background-color: transparent;
                    font-weight: $font-weight-medium;
                    display: block;
                    width: 100%;
                    text-align: left;
                    position: relative;
                    font-size: $base-font-size;
                    line-height: $base-line-height - 4;



                    &:hover {
                        text-decoration: underline;
                    }

                    &::after {
                        content: "\e900";
                        font-family: "icomoon";
                        position: absolute;
                        right: 0;
                        top: 0;
                        font-size: $base-font-size - 8;
                    }
                }

                &.opened {
                    ul {
                        display: block;
                    }

                    button {
                        &::after {
                            transform: rotate(-180deg);

                        }
                    }
                }

                .contact-info {
                    color: $base-font-color;
                    margin: 0 0 15px 0;
                    font-size: $base-font-size;

                    a {
                        color: $base-color;
                        display: inline;
                        font-weight: $font-weight-normal;
                    }

                }

                .note {
                    text-align: left;
                }

                ul {
                    margin: 0 0 0 10px;
                    padding: 0;
                    list-style-type: none;
                    display: none;

                    li {
                        margin: 0 0 10px 0;

                        a {
                            font-weight: $font-weight-normal;

                            .number {
                                display: inline-block;
                                font-size: $base-font-size - 3;
                                height: 16px;
                                line-height: $base-line-height - 4;
                                background-color: $base-color;
                                color: $white-color;
                                border-radius: 100px;
                                padding: 0 7px;
                                vertical-align: middle;
                                margin: 0;
                                float: right;

                                &.red {
                                    background-color: $red-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .subtitle {
        font-size: $base-font-size + 10 !important;
        font-weight: $font-weight-normal;
        margin: -10px 0 6px 0 !important;
    }

    .text-center {
        text-align: center;
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }

    .text-md-center {
        @include respond-above(md) {
            text-align: center;
        }
    }

    .text-md-left {
        @include respond-above(md) {
            text-align: left;
        }
    }

    .text-md-right {
        @include respond-above(md) {
            text-align: right;
        }
    }
    
    .my-account-top {
        .content-text {
            margin: 0 0 36px 0;
            
            .btn {
                margin: 0 3px 6px 3px
            }
        }
        
    }

    .no-margin {
        margin: 0;
    }

    .icon-hint {
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        background-color: $yellow-color;
        position: relative;
        font-style: normal;
        margin: -2px 4px 0 4px;
        cursor: pointer;

        &::before {
            content: "?";
            font-size: $base-font-size - 3;
            font-weight: $font-weight-medium;
            position: absolute;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    .bee-logo-404 {
        display: block;
        margin: 0 auto 55px auto;
        width: 197px;
        height: 217px;
    }

    .bee-logo-payment {
        display: block;
        width: 270px;
        height: auto;
        margin: 0 auto 10px auto;
    }
    
    .bee-logo-payment-success {
        display: block;
        width: 175px;
        height: auto;
        margin: 0 auto 0 auto;
    }
    
    .bee-logo-payment-failed {
        display: block;
        width: 197px;
        height: auto;
        margin: 0 auto 0 auto;
    }

    .error404-subtitle {
        max-width: 380px;
        margin: 0 auto 40px auto;
        text-align: center;
        font-size: $base-font-size + 6;
        line-height: $base-line-height + 4;
        color: $base-font-color;
        font-weight: $font-weight-medium;
    }

    .error404-message {
        text-align: center;
    }

    .cv-progress-bar {
        display: flex;
        align-items: center;
        margin: 0 0 10px 0;

        &.with-percentage {

            .progress-bar {
                width: calc(100% - 44px);
                margin-right: 8px;
            }

            .progress-bar-percentage {
                width: 36px;
                font-weight: $font-weight-medium;
                text-align: right;
            }
        }

        &.level {
            margin: 0 -5px;
            flex-wrap: wrap;

            .progress-bar {
                margin: 0 5px 10px 5px;
                width: calc(16.6666666667% - 10px);

            }
        }

        .progress-bar {
            width: 100%;
            height: 12px;
            background-color: $base-border-color;
            border-radius: 15px;

            div {
                height: 12px;
                border-radius: 15px;
                background-color: $base-color;
                min-width: 12px;
            }
        }


    }

    a {
        @include transition-with-prefix('all', '200ms');
        color: $base-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: $base-color;
        }
    }

    button {
        color: $base-font-color;
    }

    .message {
        background-color: $yellow-color;
        border-radius: $base-border-radius;
        padding: 8px 14px;
        margin: 0 0 20px 0;
    }

    .payment-buttons {
        margin: 0 0 40px 0;


        @include respond-above(sm) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px 40px -15px;
            width: calc(100% + 30px);
        }

        .payment-btn {
            background-color: $white-color;
            border-radius: $base-border-radius;
            box-shadow: 0px 13px 33px #00000024;
            display: block;
            text-align: center;
            padding: 20px;
            color: $base-font-color;
            text-decoration: none;
            margin: 0 0 20px 0;

            i {
                font-size: $base-font-size + 11;
                display: block;
                margin: 0 0 25px 0;
                
                @include respond-above(sm) {
                    font-size: $base-font-size + 21;    
                }
            }

            p {
                margin: 0;
            }

            &:hover {
                color: $white-color;
                background-color: $base-color;
            }

            @include respond-above(sm) {
                width: calc(50% - 30px);
                margin: 0 15px;
                padding: 40px;
            }
        }
    }

    .payment-details {
        background-color: $white-color;
        border-radius: $base-border-radius;
        padding: 30px 0 20px 0;
        box-shadow: 0px 13px 33px #00000024;
        
        @include respond-above(sm) {
            padding: 30px 0;    
        }

        .payment-qr-code {
            width: 160px;
            height: auto;
            margin: 0 auto;
            display: block;
        }

        .payment-info {
            line-height: $base-line-height + 6;
            padding: 30px 20px 0 20px;
            
            @include respond-above(sm) {
                padding: 30px 0 0 0;    
            }
            @include respond-above(md) {
                padding: 30px 30px 0 30px;    
            }
        }
    }

    .btn {
        display: inline-block;
        padding: 6px 14px;
        font-size: $base-font-size;
        font-weight: $font-weight-medium;
        border-radius: $base-border-radius;
        cursor: pointer;
        border-width: 1px;
        border-style: solid;
        line-height: 20px;
        background-color: $base-color;
        color: $white-color;
        border-color: $base-color;
        cursor: pointer;
        @include transition-with-prefix('all', '200ms');

        @include respond-above(md) {
            line-height: $base-line-height + 2;
        }

        &:hover {
            text-decoration: none;
            background-color: $base-hover-color;
            color: $white-color;

        }

        &.btn-white {
            background-color: $white-color;
            color: $base-color;
            border-color: $white-color;

            &:hover {
                background-color: $base-hover-color;
                color: $white-color;
                border-color: $base-hover-color;
            }
        }

        &.btn-yellow {
            background-color: $yellow-color;
            color: $base-font-color;
            border-color: $yellow-color;

            &:hover {
                background-color: $yellow-hover-color;
                border-color: $yellow-hover-color;
            }

            i {
                font-size: $base-font-size - 5;
                margin: -3px 5px 0 0;
                vertical-align: middle;
                display: inline-block;
            }
        }

        &.btn-white-bordered {
            background-color: transparent;
            color: $white-color;
            border-color: $white-color;
            font-weight: $font-weight-normal;

            &:hover {
                background-color: $base-hover-color;
                color: $white-color;
                border-color: $base-hover-color !important;
            }
        }

        &.btn-white-bordered-grey {
            background-color: transparent;
            color: $base-font-color;
            border-color: $base-border-color;
        }

        &.btn-big {
            width: 190px;
            padding: 12px 14px;
        }
    }

    .box-title {
        font-size: $base-font-size + 12;
        line-height: $base-line-height + 10;
        font-weight: $font-weight-medium;
        margin: 0 0 20px 0;

        @include respond-above(md) {
            font-size: $base-font-size + 14;
            line-height: $base-line-height + 12;
            margin: 0 0 40px 0;
        }
    }

    .box-share-buttons {
        display: flex;
        list-style-type: none;
        align-items: center;
        padding: 0;
        margin: 0;

        li {
            margin: 0 5px;


            &:first-child {
                display: none;

                @include respond-above(xsm) {
                    display: block;
                }
            }


            a {
                display: block;
                width: 30px;
                height: 30px;
                line-height: 30px;
                font-size: $base-font-size - 2;
                text-align: center;
                border: 1px solid $base-border-color;
                color: $base-font-color;
                border-radius: $base-border-radius;

                @include respond-above(md) {
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                    font-size: $base-font-size;
                }

                &:hover {
                    border-color: $white-color;
                    box-shadow: 0px 13px 33px #00000024;
                    text-decoration: none;
                }
            }
        }
    }

    .ajax-loader-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $base-z-index;
        background-color: rgba(255, 255, 255, 0.5);

        .ajax-loader-content {
            position: fixed;
            left: calc(50% - 25px);
            top: calc(50% - 25px);

            @include respond-above(lg) {
                left: calc(50% + 145px);
            }

            .ajax-loader {
                display: inline-block;
                width: 50px;
                height: 50px;
            }

            .ajax-loader {
                &::after {
                    content: " ";
                    display: block;
                    width: 50px;
                    height: 50px;
                    margin: 0;
                    border-radius: 50%;
                    border: 6px solid $base-font-color;
                    border-color: $base-font-color transparent $base-font-color transparent;
                    animation: loader 1.2s linear infinite;
                }
            }
        }
    }

    .login-options {
        text-align: center;
        margin-top: -5px;

        .forgotten-password {
            color: $base-font-color;
            margin: 0 0 30px 0;
            display: inline-block;
        }

        p {
            margin: 0 0 10px 0 !important;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            display: flex;
            justify-content: center;

            li {
                margin: 0 5px;

                a {
                    font-size: $base-font-size;
                    border: 1px solid $base-border-color;
                    padding: 0 15px;
                    height: 46px;
                    line-height: 46px;
                    display: block;
                    color: $base-font-color;
                    border-radius: $base-border-radius;

                    &:hover {
                        border-color: #fff;
                        -webkit-box-shadow: 0px 13px 33px rgb(0 0 0 / 14%);
                        box-shadow: 0px 13px 33px rgb(0 0 0 / 14%);
                    }

                    i {
                        display: inline-block;
                        vertical-align: middle;
                        font-size: $base-font-size;
                        margin-right: 0;
                        margin-top: -4px;

                        @include respond-above(xsm) {
                            margin-right: 10px;
                        }
                    }

                    @include respond-above(xsm) {
                        padding: 0 10px;
                        height: 36px;
                        line-height: 36px;
                    }

                    span {
                        display: none;

                        @include respond-above(xsm) {
                            display: inline-block;
                        }
                    }
                }
            }
        }

        .login-registration {
            margin: 0;
        }
    }

    .main {

        overflow-x: hidden;

        @include respond-above(xl) {
            overflow-x: visible;
        }

        .with-steps {

            @include respond-above(xl) {
                display: flex;
            }
        }

        .main-with-subheader {
            background-color: $base-color;
            color: $white-color;
            text-align: center;
            padding: 40px 0 80px 0;

            h1 {
                font-size: $base-font-size + 10;
                line-height: $base-line-height + 8;
                margin: 0;
                padding: 0;

                br {
                    display: none;

                    @include respond-above(md) {
                        display: block;
                    }
                }

                @include respond-above(md) {
                    font-size: $base-font-size + 14;
                    line-height: $base-line-height + 12;
                }
            }
        }

        .main-wrapper {
            display: block;
            margin: 0;
            padding: 22px 0 0 0;

            @include respond-above(lg) {
                margin: 0 -20px;
                padding: 40px 0 0 0;
            }

            @include respond-above(lg) {
                display: flex;
            }

            .main-sidebar {
                max-width: 340px;
                width: 100%;
                padding: 0;
                position: fixed;
                left: -100%;
                top: 0;
                height: 100%;
                background-color: $white-color;
                z-index: $base-z-index + 30;

                @include transition-with-prefix('left', '200ms');

                &.active {
                    left: 0;
                }


                @include respond-above(lg) {
                    position: relative;
                    margin: 0 20px;
                    max-width: none;
                    width: 300px;
                    left: auto;
                    top: auto;
                    height: auto;
                    z-index: auto;
                }

                .box-advertisement {
                    padding: 0;
                    margin: 0 0 40px 0;
                    display: block;
                }

                .box-agent {
                    padding: 24px 105px 24px 24px;
                    margin: 0 0 10px 0;

                    &::after {
                        width: 101px;
                        height: 161px;
                        top: auto;
                        bottom: 24px;
                        right: -19px;
                    }
                }
            }

            .main-content {
                width: auto;
                margin: 0;

                @include respond-above(lg) {
                    width: calc(100% - 380px);
                    margin: 0 20px;
                }
            }
        }



        .main-content-center {
            padding: 40px 0 80px 0;
            width: 100%;
            max-width: 558px;
            margin: 0 auto;

            &.wider {
                max-width: 752px;
            }

            .content-tabs {
                margin: 0 0 24px 0;
                padding: 0;
                list-style-type: none;
                display: flex;
                justify-content: center;

                li {
                    margin: 0 5px;

                    &.active {
                        a {
                            background-color: $base-font-color;
                            color: $white-color;
                            border-color: $base-font-color;
                        }
                    }

                    a {
                        color: $base-font-color;
                        font-weight: $font-weight-medium;
                        padding: 7px 13px;
                        display: block;
                        border: 1px solid $base-border-color;
                        border-radius: $base-border-radius;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            &.cv-create {
                .content-text {
                    margin: 0 0 80px 0;
                }
            }

            &.position-detail {
                .content-text {
                    text-align: left;

                    ul {
                        margin: 0;
                        padding: 0;

                        li {
                            margin-bottom: 15px;
                        }
                    }
                }

                .offer-list-content {
                    margin: 0 0 80px 0 !important;
                }
            }

            .header-top-image {
                width: 275px;
                margin: -80px auto 30px auto;
                display: block;
            }

            .content-text {
                text-align: center;
                margin: 0 0 40px 0;


                &.grey-color {
                    color: $grey-color;
                    margin: 0 0 40px 0;
                }

                &.advertising {
                    margin: 0;

                    h2 {
                        margin: 0 0 10px 0;
                    }

                    p {
                        font-size: $base-font-size + 1;
                        line-height: $base-line-height - 3;
                        margin: 0 0 30px 0;

                        @include respond-above(md) {
                            font-size: $base-font-size + 2;
                            line-height: $base-line-height - 2;
                            margin: 0 0 40px 0;
                        }

                    }

                    .btn {
                        padding: 12px 14px;
                        min-width: 170px;
                        margin: 0 0 20px 0;
                    }

                    .note {
                        font-size: $base-font-size - 1;
                    }
                }

                &.position-detail,
                &.terms-conditions,
                &.article-list-content,
                &.article-detail,
                &.company-detail {
                    text-align: left;

                    h2 {
                        text-align: left;
                        font-size: $base-font-size + 10;
                        line-height: $base-line-height + 8;
                        margin: 0 0 20px 0;
                    }

                    h3 {
                        font-size: $base-font-size + 6;
                        line-height: $base-line-height + 4;
                        margin: 0 0 20px 0;
                        padding: 0 0 0 20px;
                    }

                    h4 {
                        font-size: $base-font-size + 2;
                        line-height: $base-line-height - 2;
                        margin: 0 0 8px 0;
                        padding: 0;
                    }

                    p {
                        margin: 0 0 10px 0;
                    }

                    ol {
                        margin: 0 0 40px 0;
                        padding: 0 0 0 35px;

                        li {
                            margin: 0 0 10px 0;

                            &::marker {
                                font-weight: $font-weight-medium;
                            }

                            ul {
                                list-style-type: disc;
                                padding: 0 0 0 25px;
                                margin: 0 0 10px 0;
                            }

                            ol {
                                list-style-type: lower-alpha;
                                margin: 0 0 10px 0;
                                padding: 0 0 0 25px;

                                li {
                                    &::marker {
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                    }
                }

                &.company-detail {
                    p {
                        margin: 0 0 24px 10px;
                    }

                    h3 {
                        padding: 0;
                    }
                }

                &.article-detail {
                    &.without-buttons {
                        padding-bottom: 46px;
                    }

                    &.cv-detail.without-buttons {
                        padding-bottom: 0;

                        .box-white-article {
                            padding-bottom: 46px;
                        }
                    }

                    margin: 0;

                    img {
                        display: block;
                        width: 100%;
                        max-width: 100%;
                        margin: 0 0 40px 0;
                    }

                    p {
                        margin: 0 0 20px 0;

                        strong {
                            font-weight: $font-weight-medium;
                        }
                    }

                    ul {
                        margin: 0 0 40px 0;
                        padding: 0 0 0 35px;

                        li {
                            margin: 0 0 20px 0;
                        }
                    }

                    h3 {
                        padding: 0;
                    }

                    time {
                        display: block;
                        font-size: $base-font-size - 2;
                        line-height: $base-line-height;
                        color: $grey-color;
                        margin: 0 0 10px 0;
                    }

                    .article-detail-float-button {
                        text-align: center;
                        width: 100%;
                        left: 0;



                        a,
                        button {
                            display: inline-block;
                            padding: 13px;
                            margin: 0 10px 15px 10px;
                            background-color: $base-color;
                            color: $white-color;
                            font-size: $base-font-size;
                            font-weight: $font-weight-medium;
                            border: 0;
                            border-radius: $base-border-radius;
                            min-width: 190px;
                            text-decoration: none;

                            &.btn-yellow {
                                background-color: $yellow-color;
                                color: $base-font-color;

                                i {
                                    font-size: $base-font-size - 5;
                                    margin: -3px 5px 0 0;
                                    vertical-align: middle;
                                    display: inline-block;
                                }

                                &:hover {
                                    background-color: $yellow-hover-color;
                                }
                            }

                            @include transition-with-prefix('background-color', '200ms');

                            &:hover {
                                background-color: $base-hover-color;
                            }

                        }
                    }
                }

            }

            &.cv-create {

                margin: 0 auto;

                @include respond-above(xl) {
                    margin: 0;
                }

                h2 {
                    text-align: left;

                    &.mb-20 {
                        margin-bottom: 20px;
                    }
                }

                h3 {
                    font-size: $base-font-size + 4;
                    line-height: $base-line-height + 1;

                    &.mb-20 {
                        margin-bottom: 20px;
                    }
                }

                .cv-create-float-button {
                    .btn {
                        margin: 0;
                    }

                    input[type="submit"] {
                        margin: 0 0 15px 0;
                    }

                    .fixed-element {
                        @include transition-with-prefix('background-color', '200ms');
                    }

                    .cv-create-submit {
                        order: 2;
                        text-align: center;

                        .note {
                            text-align: center;

                            @include respond-above(sm) {
                                text-align: left;
                            }
                        }

                        @include respond-above(sm) {
                            order: 1;
                            text-align: left;
                        }
                    }

                    .cv-create-view {
                        order: 1;

                        .form-element {
                            text-align: center;

                            @include respond-above(sm) {
                                text-align: right;
                            }
                        }

                        @include respond-above(sm) {
                            order: 2;
                        }
                    }
                }
            }

            h1 {
                font-size: $base-font-size + 14;
                line-height: $base-line-height + 12;
                margin-bottom: 40px;
                text-align: center;

                img {
                    display: block;
                    margin: 0 auto 20px auto;
                    width: 40px;
                    height: 40px;

                    &.bee-logo {
                        width: 175px;
                        height: 217px;
                    }
                }
            }

            h2 {
                font-size: $base-font-size + 6;
                line-height: $base-line-height + 4;
                margin: 0 0 32px 0;
                text-align: center;

                &.offer-title {
                    margin: 0 0 4px 0;
                }

                .step-number {
                    display: inline-block;
                    background-color: $base-color;
                    color: $white-color;
                    width: 23px;
                    height: 23px;
                    line-height: 23px;
                    text-align: center;
                    font-size: $base-font-size - 1;
                    font-weight: $font-weight-medium;
                    border-radius: 50%;
                    margin: -4px 8px 0 0;
                    vertical-align: middle;
                }
            }

            .offer-company {
                text-align: center;
            }

            .registration-text {
                margin-bottom: 40px;
            }

            form {
                input[type="submit"] {
                    min-width: 190px;
                    margin: 0 10px 15px 10px;
                    padding: 13px 10px;
                    width: auto;
                    display: inline-block;
                }

                .btn {
                    min-width: 190px;
                    margin: 0 10px 15px 10px;
                    padding: 13px 10px;
                    width: auto;
                    display: inline-block;

                    &.btn-sm {
                        padding: 6px 14px;
                        min-width: 0;
                        margin: 0 10px 15px 0;
                    }
                }
            }
        }

        .box-white {
            border: 1px solid $base-border-color;
            border-radius: $base-border-radius;
            padding: 24px;
            margin: 0 0 10px 0;

            .form-element {
                label {
                    font-size: $base-font-size + 2;
                    line-height: $base-line-height - 2;
                    font-weight: $font-weight-medium;
                    margin: 0 0 10px 0;
                }

                &:last-child {
                    margin: 0;
                }

                .switch {
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        .back-link {
            margin: 0 0 20px 0;

            a {
                font-size: $base-font-size - 1;

                @include respond-above(md) {
                    font-size: $base-font-size;
                }

                i {
                    display: inline-block;
                    font-size: $base-font-size - 4;
                    margin: -3px 10px 0 0;
                    vertical-align: middle;
                }
            }
        }

        .custom-banner {
            margin: 0 0 20px 0;

            img {
                display: block;
                width: 100%;
                max-width: 100%;
            }
        }

        .next-link {
            margin: 0 0 20px 0;

            a {
                font-size: $base-font-size - 1;

                @include respond-above(md) {
                    font-size: $base-font-size;
                }

                i {
                    display: inline-block;
                    font-size: $base-font-size - 4;
                    margin: -3px 0 0 10px;
                    vertical-align: middle;
                    transform: rotate(180deg);
                }
            }
        }


        .offer-company {
            line-height: $base-line-height - 2;
            margin: 0 0 30px 0;

            a {
                font-size: $base-font-size;
                color: $grey-color;
            }
        }

        .offer-statistics-info {
            margin: 0 0 20px 0;

            i {
                color: $base-color;
                display: inline-block;
                vertical-align: middle;
                margin: -2px 4px 0 0;
                font-size: $base-font-size - 1;
            }

            span {
                font-size: $base-font-size - 1;
                font-weight: $font-weight-medium;
                margin: 0 10px 0 0;
                display: inline-block;
            }
        }
    }

}
