.box-agent-cv {
    
    position: relative;
    margin: -24px 0 24px 0;
    
    @include respond-above(sm) {
        display: flex;
        flex-wrap: wrap;     
    }

    &::after {
        display: none;
        content: "";
        position: absolute;
        left: calc(50% - 82px);
        top: calc(50% - 98px);
        width: 164px;
        height: 195px;
        @include background-2x('../assets/avatar6', 'png', auto, 100%, 0 0, no-repeat);
        
        @include respond-above(md) {
            display: block;
        }
        
    }

    .box-agent-cv-left,
    .box-agent-cv-right {
        width: auto;
        text-align: center;
        padding: 30px 20px 30px 20px;
        
        @include respond-above(sm) {
            width: 50%;    
        }
        
        @include respond-above(md) {
            padding: 30px 100px 30px 100px;    
        }

        .box-agent-cv-title {
            font-size: $base-font-size + 4;
            line-height: $base-line-height + 1;
            font-weight: $font-weight-medium;
            margin: 0 0 15px 0;
            
            @include respond-above(sm) {
                font-size: $base-font-size + 6;
                line-height: $base-line-height + 4;    
            }
        }

        .box-agent-cv-desc {
            font-size: $base-font-size;
            line-height: $base-line-height - 2;
            margin: 0 0 24px 0;
            
            @include respond-above(sm) {
                line-height: $base-line-height + 2;    
            }
        }

    }

    .box-agent-cv-left {
        background-color: $base-color;
        color: $white-color;
        
    }

    .box-agent-cv-right {
        background-color: $yellow-color;

        .btn {
            background-color: $base-font-color;
            color: $yellow-color;
            border-color: $base-font-color;
            
            &:hover {
                background-color: #000000;
            }
        }
    }
}
