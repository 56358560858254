.paginator {
    display: flex;
    list-style-type: none;
    margin: 0 0 80px 0;
    padding: 0;

    li {

        &.current {
            a {
                color: $grey-color;
            }
        }

        a,
        span {
            font-size: $base-font-size + 2;
            line-height: $base-line-height - 2;
            font-weight: $font-weight-medium;
            color: $base-font-color;
            margin: 0 9px;
            display: inline-block;
            height: 30px;
            line-height: $base-line-height + 10;

            i {
                display: inline-block;
            }

            @include respond-above(md) {
                font-size: $base-font-size + 4;
                margin: 0 10px;
            }
        }


        &:first-child,
        &:last-child {

            a,
            span {
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: $base-line-height + 10;
                font-size: $base-font-size - 8;
                color: $white-color;
                border-radius: $base-border-radius;
            }

            a {
                background-color: $base-color;

                &:hover {
                    background-color: $base-hover-color;
                }
            }

            span {
                background-color: $base-border-color;
            }
        }

        &:first-child {

            a,
            span {
                margin-left: 0;

                i {
                    transform: rotate(90deg);

                }
            }
        }

        &:last-child {

            a,
            span {
                margin-right: 0;

                i {
                    transform: rotate(-90deg)
                }

            }
        }
    }
}
