.company-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0 0 20px 0;
    border-bottom: 1px solid $base-border-color;
    a {
        text-transform: uppercase;
        color: $base-font-color;
        width: 16px;
        height: 16px;
        line-height: $base-line-height - 4;
        text-align: center;
        display: block;
        margin: 0 2px 10px 2px;
        &:hover {
            background-color: $base-color;
            color: $white-color;
            text-decoration: none;
            border-radius: 3px;
        }
    }
}

.company-list-wrapper {
    .company-list {
        border-bottom: 1px solid $base-border-color;
        padding: 0 0 30px 0;
        margin: 0 0 30px 0;
        .company-list-title {
            text-transform: uppercase;
            font-size: $base-font-size + 2;
            font-weight: $font-weight-medium;
            margin: 0 0 5px 0;
        }
        .company-links {
            list-style-type: none;
            margin: 0 0 0 10px;
            padding: 0;
            li {
                a {
                    color: $base-font-color;
                    &:hover {
                        text-decoration: none;
                        color: $base-color;
                    }
                }
            }
        }
        &:last-of-type {
            border-bottom: 0;
        }
    }
}