.menu-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $yellow-color;
    display: flex;
    flex-wrap: wrap;
    z-index: $base-z-index + 11;
    margin: 0;
    padding: 0;
    list-style-type: none;
    height: 60px;
    align-items: center;

    @include respond-above(md) {
        display: none;
    }

    li {
        width: 25%;
        text-align: center;

        a {
            display: block;
            color: $base-font-color;
            height: 60px;
            padding: 12px 0 0 0;
            text-decoration: none;

            &::before {
                color: $base-font-color;
                font-size: $base-font-size + 4;
                display: inline-block;
                line-height: $base-line-height - 2;
            }

            &.icon-mail {
                &::before {
                    font-size: $base-font-size - 1;
                }

            }

            span {
                display: block;
                padding: 7px 0 0 0;
                font-size: $base-font-size - 4;
                line-height: $base-line-height - 8;
                font-family: $font-family;
            }

            &:hover {
                &::before {
                    opacity: 1;
                }

                span {
                    color: $base-font-color;
                }
            }
        }
    }
}
