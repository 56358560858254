.box-chat {
    position: fixed;
    width: 48px;
    height: 48px;
    right: 30px;
    bottom: 25px;
    display: none;
    z-index: $base-z-index + 10;
    
    @include respond-above(lg) {
        display: block;
    }

    button {
        margin: 0;
        padding: 0;
        border: 0;
        background-color: $yellow-color;
        box-shadow: 0px 12px 30px #00000033;
        border-radius: 1000px 1000px 0px 1000px;
        width: 48px;
        height: 48px;
        font-size: $base-font-size + 4;
    }
}
