.services-list-content {
    .box-service {
        
        background-color: $white-color;
        box-shadow: 0px 13px 33px #00000024;
        border-radius: $base-border-radius;
        padding: 30px 30px 20px 30px;
        margin: 0 0 40px 0;
        position: relative;
        
        .icon-close {
            font-size: $base-font-size;
            background-color: transparent;
            border: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: $base-font-color;    
        }

        .box-service-title {
            text-align: center;
            font-size: $base-font-size + 6;
            line-height: $base-line-height + 4;
            margin: 0 0 30px 0;
            padding: 0;
        }
        
        .service-message {
            text-align: center;
            margin: 0 0 40px 0;
        }

        .service-table-wrapper {
            margin: 0 0 35px 0;
            &::before {
                content: "\e91a";
                font-family: "icomoon";
                display: block;
                margin: 0 0 10px 0;
                animation: swipe-left-right 2s infinite;
                position: relative;

                @include respond-above(md) {
                    display: none;
                }
            }

            .service-table {
                margin: 0;
                padding: 0;
                white-space: nowrap;
                overflow-x: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                @include respond-above(md) {
                    white-space: normal;
                    overflow-x: auto;
                }

                table {
                    font-size: $base-font-size;
                    width: 100%;
                    line-height: $base-line-height;

                    tr {

                        th {

                            font-weight: $font-weight-medium;
                            vertical-align: top;
                            border-bottom: 1px solid $base-border-color;
                            padding: 0 0 5px 0;

                            &.text-right {
                                text-align: right;
                                padding: 0 0 5px 20px;
                            }

                        }

                        td {
                            padding: 5px 0;
                            vertical-align: top;

                            &.text-right {
                                text-align: right;
                                padding: 5px 0 5px 20px;
                            }
                            
                            strong {
                                font-weight: $font-weight-medium;
                            }

                        }

                    }
                }
            }
        }
        .service-buttons {
            text-align: center;
            .btn {
                margin: 0 10px 10px 10px;
            }
        }
    }
}
