@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.offer-filter-header {
    justify-content: space-between;
    padding: 20px;
    position: relative;

    @include respond-above(lg) {
        display: none;
    }

    .offer-filter-title {
        font-size: $base-font-size + 2;
        line-height: $base-line-height;
        font-weight: $font-weight-medium;
        max-width: calc(100% - 60px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .icon-close {
        margin: 0;
        padding: 23px;
        border: 0;
        background: transparent;
        font-size: $base-font-size;
        position: absolute;
        right: 0;
        top: 0;

    }
}

.offer-filter-search {
    position: absolute;
    bottom: 20px;
    left: 45px;
    box-shadow: 0px 12px 30px #00000033;
    width: 240px;
    z-index: $base-z-index + 15;
    padding: 6px 10px;

    @include respond-above(lg) {
        display: none !important;
    }
}

.offer-filter-scroll-content {
    padding: 0 20px 50px 20px;
    height: calc(100% - 50px);
    overscroll-behavior: contain;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-color: #e5e5e5 $white-color;

    &::-webkit-scrollbar {
        width: 11px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        width: 11px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #e5e5e5;
        border-right: 8px solid $white-color;
        border-top: 20px solid $white-color;
        border-bottom: 20px solid $white-color;
        border-radius: $base-border-radius;

        &:hover {
            background-color: #e5e5e5;
        }

        &:active {
            background-color: #e5e5e5;
        }

        &:vertical {
            min-height: 175px;
        }
    }

    @include respond-above(lg) {
        padding: 0;
        overflow: visible;
    }
}

.form-offer-filter {
    margin: 0 0 40px 0;
}

.btn-filter {
    margin: 0 0 15px 0;
    padding: 0;
    background: transparent;
    font-size: $base-font-size - 1;
    line-height: $base-line-height - 6;
    border: 0;
    color: $base-color;

    &.fixed {
        position: fixed;
        top: 80px;
        left: 50%;
        background-color: $base-color;
        color: $white-color;
        height: 34px;
        line-height: 34px;
        font-weight: $font-weight-medium;
        width: 190px;
        text-align: center;
        font-size: $base-font-size - 1;
        border-radius: $base-border-radius;
        margin-left: -95px;
        box-shadow: 0px 12px 30px rgb(0 0 0 / 20%);
        z-index: $base-z-index;
        @include transition-with-prefix('background-color', '200ms');

        &:hover {
            background-color: $base-hover-color;
        }

    }

    @include respond-above(lg) {
        display: none;
    }
}

.offer-list-content {
    position: relative;

    .custom-banner {
        margin: 0 0 20px 0;

        img {
            display: block;
            width: 100%;
            max-width: 100%;
        }
    }

    &.empty {
        .offer-list-company {
            padding: 0;
            border-bottom: 0;
        }

        .offer-list-message {
            margin: 0 0 80px 0;
            padding: 0;
            border-bottom: 0;
        }

    }

    .offer-list-company {
        padding: 0 0 24px 0;
        border-bottom: 1px solid $base-border-color;
        margin: 0 0 24px 0;

        .offer-list-company-text {
            font-size: $base-font-size;
            line-height: $base-line-height;
            height: 60px;
            overflow: hidden;
            text-overflow: ellipsis;
            @include transition-with-prefix('height', '200ms');
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            &.active {
                height: auto;
                display: block;
            }

            a,
            strong {
                font-weight: $font-weight-medium;
            }
        }

        .offer-list-company-text-btn {
            margin: 0;
            padding: 0;
            border: 0;
            background: transparent;
            color: $base-color;
            font-size: $base-font-size;

            &:hover {
                text-decoration: underline;
            }
        }

    }

    &.with-fixed-btn-filter {
        padding: 29px 0 0 0;
    }

    h1 {
        font-size: $base-font-size + 6;
        line-height: $base-line-height + 4;
        font-weight: $font-weight-medium;
        margin: 0 0 8px 0;

        @include respond-above(md) {
            font-size: $base-font-size + 10;
            line-height: $base-line-height + 8;
        }

        sup {
            color: $grey-color;
            font-size: $base-font-size;
            line-height: $base-line-height - 4;
            font-weight: $font-weight-normal;

            @include respond-above(md) {
                font-size: $base-font-size + 2;
                line-height: $base-line-height - 2;
            }
        }
    }

    .breadcrumbs {
        ul {
            padding: 0;
            margin: 0 0 5px 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;

            li {
                font-size: $base-font-size - 2;
                color: $grey-color;
                line-height: $base-line-height - 7;

                &::after {
                    content: "/";
                    color: $base-font-color;
                    margin: 0 4px;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }

                a {
                    color: $grey-color;
                }
            }

            &:last-child {
                margin: 0 0 24px 0;
            }
        }
    }

    .offer-list-message {
        font-size: $base-font-size + 4;
        line-height: $base-line-height + 1;
        font-weight: $font-weight-medium;
        padding: 0 0 15px 0;
        border-bottom: 1px solid $base-border-color;
        margin: 0 0 15px 0;
        text-align: center;

        @include respond-above(sm) {
            font-size: $base-font-size + 6;
            line-height: $base-line-height + 4;
            padding: 0 0 24px 0;
            margin: 0 0 15px 0;
        }
    }

    .offer-list-more {
        i {
            display: inline-block;
            font-size: $base-font-size - 4;
            margin: -3px 0 0 10px;
            vertical-align: middle;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }

    .offer-list {

        .offer-highlight-slider {
            margin: 0 0 24px 0;
            box-shadow: 0px 13px 33px #00000024;
            border-radius: $base-border-radius;
        }

        .offer {
            border-bottom: 1px solid $base-border-color;
            padding: 0 15px 15px 15px;
            margin: 0 0 15px 0;

            @include respond-above(sm) {
                padding: 0 24px 18px 24px;
                margin: 0 0 24px 0;
            }

            &.premium {
                background-color: $yellow-color;
                margin: -15px 0 15px 0;
                padding: 15px 15px 9px 15px;

                @include respond-above(sm) {
                    padding: 24px 24px 18px 24px;
                    margin: -24px 0 24px 0;
                }

                .offer-top {
                    .offer-top-left {
                        .offer-title {
                            a {
                                color: $base-font-color;
                            }
                        }

                        .offer-locality {
                            color: #5d5d5d;
                        }
                    }
                }
                
                .offer-bottom {
                    .offer-bottom-left {
                        .offer-info {
                            li {
                                border: 1px solid #CBAE5D;
                            }
                        }
                    }
                    .offer-bottom-right {
                        .date {
                            color: #5d5d5d;
                        }
                    }    
                }
            }

            .offer-top {

                display: flex;
                justify-content: space-between;

                .offer-top-left {
                    .offer-title {
                        margin: 0 0 10px 0;
                        font-size: $base-font-size + 4;
                        line-height: $base-line-height + 1;
                        font-weight: $font-weight-medium;
                        text-align: left;
                       

                        @include respond-above(md) {
                            font-size: $base-font-size + 6;
                            line-height: $base-line-height + 4;
                            
                
                        }
                        
                        a {
                            display: -webkit-box;
                            max-height: 64px;
                            overflow: hidden;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                            
                            @include respond-above(md) {
                                max-height: none;
                                display: inline-block;
                            }
                            
                        }

                    }

                    .offer-employer {
                        margin: 0 0 4px 0;

                        a {
                            color: $base-font-color;
                            display: inline-block;
                            line-height: $base-line-height - 4;
                        }
                    }

                    .offer-locality {
                        color: $grey-color;
                        font-size: $base-font-size - 2;
                        line-height: $base-line-height - 7;
                        margin: 0 0 12px 0;

                        @include respond-above(sm) {
                            margin: 0 0 15px 0;
                        }
                    }
                }

                .offer-top-right {
                    

                    .offer-company-logo {
                        display: inline-block;
                        margin-left: 10px;
                        
                        @include respond-above(sm) {
                            margin-left: 20px; 
                        }

                        img {
                            width: 37px;

                            @include respond-above(sm) {
                                width: 50px;
                            }
                            
                            @include respond-above(md) {
                                width: 70px;
                            }
                        }
                    }
                }
            }

            .offer-bottom {

                @include respond-above(sm) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .offer-bottom-left {
                    margin: 0 0 14px 0;

                    @include respond-above(sm) {
                        margin: 0;
                    }

                    .offer-info {
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0;
                        padding: 0;
                        list-style-type: none;

                        li {
                            border: 1px solid $base-border-color;
                            border-radius: $base-border-radius;
                            font-size: $base-font-size - 2;
                            font-weight: $font-weight-medium;
                            padding: 0 11px;
                            line-height: $base-line-height + 7;
                            margin: 0 6px 6px 0;

                            &:last-child {
                                margin: 0 0 6px 0;
                            }

                            img {
                                display: inline-block;
                                vertical-align: middle;
                                height: 13px;
                                margin: -2px 6px 0 0;
                            }
                        }
                    }

                    span {
                        font-size: $base-font-size - 2;
                        background-color: $yellow-color;
                        line-height: $base-line-height - 7;
                        font-size: $base-font-size - 2;
                        font-weight: $font-weight-medium;
                        padding: 8px 12px;
                        border-radius: $base-border-radius;
                    }
                }

                .offer-bottom-right {
                    margin: 0 0 6px 0;

                    @include respond-above(sm) {
                        display: flex;
                        align-items: center;
                    }

                    .top {
                        border: 1px solid $red-color;
                        background-color: $red-color;
                        color: $white-color;
                        text-transform: uppercase;
                        font-size: $base-font-size - 3;
                        height: 20px;
                        line-height: $base-line-height - 2;
                        font-weight: $font-weight-medium;
                        border-radius: $base-border-radius;
                        padding: 0 6px;
                        margin: 0 10px 0 0;
                        display: inline-block;
                    }
                    
                    .premium {
                        border: 1px solid $base-font-color;
                        background-color: $base-font-color;
                        color: $yellow-color;
                        text-transform: uppercase;
                        font-size: $base-font-size - 3;
                        height: 20px;
                        line-height: $base-line-height - 2;
                        font-weight: $font-weight-medium;
                        border-radius: $base-border-radius;
                        padding: 0 6px;
                        margin: 0 10px 0 0;
                        display: inline-block;
                    }

                    .date {
                        font-size: $base-font-size - 2;
                        color: $grey-color;
                        line-height: $base-line-height + 2;
                        margin: 0 10px 0 0;
                        display: inline-block;

                    }

                    .icon-heart-full {
                        color: $base-color;
                    }

                    .icon-heart,
                    .icon-heart-full {
                        margin: 0;
                        padding: 0;
                        border: 0;
                        height: 22px;
                        display: inline-block;
                        background: transparent;
                        font-size: $base-font-size + 6;
                        float: right;
                        @include transition-with-prefix('color', '200ms');

                        &.favorite {
                            color: $base-color;

                            &::before {
                                content: "\e906";
                            }
                        }

                        &:hover {
                            color: $base-color;
                        }

                        @include respond-above(md) {
                            float: none;
                            height: 24px;
                            font-size: $base-font-size + 10;
                        }
                    }

                }
            }

            &.highlight {
                border-radius: $base-border-radius;
                border-bottom: 0;
                padding: 15px 15px 3px 15px;
                margin: 0;
                display: block;

                @include respond-above(sm) {
                    padding: 24px 24px 9px 24px;
                }

                .offer-top {
                    .offer-top-left {
                        width: 100%;

                        @include respond-above(sm) {
                            width: calc(100% - 120px);
                        }

                        .offer-title {
                            color: $red-color;

                            a {
                                color: $red-color;
                                display: inline-block;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 100%;
                            }
                        }

                        .offer-employer {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 100%;
                        }

                        .offer-locality {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 100%;
                        }
                    }

                    .offer-top-right {
                        display: none;
                        
                        @include respond-above(sm) {
                            display: block;    
                        }
                        
                        .offer-company-logo {
                            img {
                                width: 100px;
                            }
                        }
                    }
                }
            }
        }

        .box-advertisement {
            text-align: center;
            margin: -25px 0 24px 0;
            width: 100%;

            img {
                display: block;
                width: 100%;
                max-width: 100%;
            }
        }

        .box-top-employers {
            margin: -16px 0 24px 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            border-bottom: 1px solid $base-border-color;
            border-top: 1px solid $base-border-color;

            @include respond-above(sm) {
                margin: -25px 0 24px 0;
            }

            li {
                width: 33.3333%;
                border-right: 1px solid $base-border-color;

                &:last-child {
                    display: none;
                }

                &:nth-child(3) {
                    border-right: 0;
                }

                @include respond-above(sm) {
                    width: 25%;

                    &:last-child {
                        display: block;
                    }

                    &:nth-child(3) {
                        border-right: 1px solid $base-border-color;
                    }
                }

                &:last-child {
                    border-right: 0;
                }

                a {
                    display: block;
                    height: 100px;
                    padding: 10px 0 0 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    position: relative;

                    @include respond-above(sm) {
                        padding: 20px 0 0 0;
                        height: 139px;
                    }



                    &:hover {
                        text-decoration: none;

                        &::after {
                            content: "";
                            display: block;
                            width: calc(100% + 2px);
                            height: calc(100% + 40px);
                            border-left: 1px solid $white-color;
                            border-right: 1px solid $white-color;
                            background-color: transparent;
                            position: absolute;
                            left: -1px;
                            top: 0;
                            box-shadow: 0px 12px 30px #0000001A;

                        }
                    }

                    img {
                        max-width: 80px;
                        max-height: 45px;

                        @include respond-above(sm) {
                            max-width: 90px;
                            max-height: 60px;
                        }
                    }

                    span {
                        display: block;
                        width: 100%;
                        text-align: center;
                        color: $base-font-color;
                        font-size: $base-font-size - 2;

                        @include respond-above(sm) {
                            font-size: $base-font-size;
                        }
                    }
                }
            }
        }
    }
}
