.box-cv-create {
    background-color: $base-color;
    color: $white-color;
    margin: 0 0 40px 0;
    border-radius: $base-border-radius;
    padding: 100px 25px 25px 25px;
    position: relative;
    box-shadow: 0px 13px 33px #00000024;
    
    @include respond-above(xsm) {
        padding: 25px;
    
    }
    
    @include respond-above(sm) {
        margin: 0 0 40px 0;    
    }
    

    .box-cv-create-title {
        font-size: $base-font-size + 4;
        line-height: $base-line-height + 3;
        font-weight: $font-weight-medium;
        margin: 0 0 25px 0;
        
        @include respond-above(xsm) {
            max-width: 50%;    
        }
    }
    
    .box-cv-create-btn {
        font-size: $base-font-size + 2;
        border-radius: $base-border-radius;
        padding: 12px 16px;
        display: inline-block;
        background-color: $white-color;
        text-decoration: none;
        
        &:hover {
            background-color: $base-hover-color;
            color: $white-color;
        }
    }
    
    .box-cv-create-desc {
        position: absolute;
        width: 200px;
        height: 155px;
        right: auto;
        left: calc(50% - 100px);
        top: -57px;
        @include background-2x('../assets/cv_box', 'png', auto, 100%, 0 0, no-repeat);
        
        @include respond-above(xsm) {
            right: -36px;
            left: auto;
            width: 250px;
            height: 205px;
        }
        
        @include respond-above(md) {
            right: -56px; 
        }
        
        span {
            display: block;
            color: $base-color;
            width: 86px;
            height: 59px;
            position: absolute;
            right: 24px;
            top: 12px;
            padding: 10px 10px;
            text-align: center;
            font-size: $base-font-size - 5;
            line-height: $base-line-height - 8;
            text-transform: uppercase;
            font-weight: $font-weight-medium;
            
            @include respond-above(xsm) {
                font-size: $base-font-size - 2;
                line-height: $base-line-height - 5;  
                width: 112px;
                height: 77px;
                padding: 15px 10px;
            }
        }
        
    }
}
