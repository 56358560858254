.footer {
    background-color: $base-font-color;
    color: $base-border-color;
    padding: 40px 0 30px 0;
    position: relative;
    margin-top: 150px;
    
    &::before {
        content: "";
        position: absolute;
        left: calc(50% - 88px);
        top: -109px;
        width: 176px;
        height: 141px;
        @include background-2x( '../assets/avatar5', 'png', auto, 100%, 0 0, no-repeat );
    }
    
    @include respond-above(md) {
        padding: 40px 0;
    }
    
    @include respond-above(lg) {
        padding: 80px 0;     
    }
    
    .box-share-buttons {
        li {
            &:first-child {
                display: block;
            }
        }
    }

    .footer-logo {
        color: $white-color;
        height: 34px;
        line-height: $base-line-height + 14;
        display: block;
        font-size: $base-font-size + 20;
        margin-bottom: 30px;
        
        @include respond-above(md) {
            margin-bottom: 40px;    
        }
        
        @include respond-above(lg) {
            margin-bottom: 0;        
        }

        &::before {
            content: "\e909";
            font-family: "icomoon";
        }

        &:hover {
            text-decoration: none;
        }
    }

    .footer-menu-btn {
        margin: 0 0 20px 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        
        @include respond-above(md) {
            margin: 0 0 40px 0;
        }
        
        @include respond-above(lg) {
            display: block;
            margin: 0;
        }

        li {
            margin: 0 10px 10px 0;
            
            &::last-child {
                margin: 0;
            }
            
            @include respond-above(md) {
                margin: 0 10px 0 0;    
            }
            
            @include respond-above(lg) {
                margin: 0 0 10px 0;    
            }

            .btn {
                border-color: $grey-color;
                background-color: transparent;
                &:hover {
                    background-color: $white-color;
                    color: $base-font-color;
                    border-color: $white-color;
                }
            }
            &:last-child {
                margin: 0;
            }
        }
    }
    
    .box-share-buttons {
        margin: 0 0 40px 0;
        li {
            font-size: $base-font-size - 1;
            a {
                border-color: $grey-color;
                color: $white-color;
                &:hover {
                    border-color: $white-color;
                    background-color: $white-color;
                    color: $base-font-color;
                }
            }
        }   
    }
    
    .footer-menu {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
            a {
                color: $base-border-color;
                font-size: $base-font-size - 2;
                
                @include respond-above(md) {
                    font-size: $base-font-size - 1;    
                }
            }
        }
    }
}
