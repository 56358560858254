.attachments {
    .attachment {
        border-bottom: 1px solid $base-border-color;
        padding: 0 0 30px 0;
        margin: 0 0 40px 0;

        h2 {
            text-align: left !important;
            margin: 0 0 20px 0 !important;
        }

        p {
            color: #5d5d5d;
            margin: 0 0 20px 0;
        }

        .btn {
            margin: 0 !important;
        }

        .box-white-form {
            padding: 20px;
            margin: 0 0 20px 0;
        }

        .attachment-name {
            white-space: nowrap;

        }

        .attachment-preview {
            display: inline-block;
            vertical-align: middle;
            width: 30px;
            border-radius: $base-border-radius;
            margin: 0 10px 0 0;

            img {
                width: 30px;
               display: block;
               border-radius: $base-border-radius;
               height: 30px;
               object-fit: cover;
               object-position: 50% 0%;
            }
        }

        .attachment-icon {
            width: 30px;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            border-radius: $base-border-radius;
            background-color: #648cba;
            color: $white-color;
            font-size: $base-font-size - 4;
            margin: 0 10px 0 0;

        }

        .attachment-wrapper {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $base-color;
            height: 30px;
            margin: 0 0 5px 0;

            @include respond-above(sm) {
                max-width: calc(100% - 80px);
                display: inline-block;
                vertical-align: middle;
                padding: 0 5px 0 0;
                margin: 0;
            }
        }

        .attachment-date {
            width: 80px;

            @include respond-above(sm) {
                display: inline-block;
                vertical-align: middle;
                margin: -2px 0 0 0;
            }
        }


        .box-white-form {

            &.png,
            &.gif,
            &.jpg,
            &.jpeg,
            &.tif {
                .attachment-icon {
                    background-color: #534ded;
                }

            }

            &.doc,
            &.docx,
            &.odt {
                .attachment-icon {
                    background-color: #1eb6ea;
                }
            }

            &.rtf {
                .attachment-icon {
                    background-color: #72b900;
                }
            }

            &.txt {
                .attachment-icon {
                    background-color: #8a8e00;
                }
            }

            &.pdf {
                .attachment-icon {
                    background-color: #eb2956;
                }
            }

            &.zip,
            &.rar {
                .attachment-icon {
                    background-color: #b16c00;
                }
            }

            &.ppt,
            &.pptx {
                .attachment-icon {
                    background-color: #f48300;
                }
            }

            &.avi {
                .attachment-icon {
                    background-color: #a058ff;
                }
            }

            .box-white-form-cv-right {
                padding: 4px 0 0 0;
            }
        }
    }
}
