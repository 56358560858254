.hint-aside {
    position: fixed;
    width: 100%;
    max-width: 300px;
    height: 100%;
    background-color: $white-color;
    right: -100%;
    top: 0;
    color: $base-font-color;
    z-index: $base-z-index + 30;
    box-shadow: 0px 13px 33px #00000024;

    &.active {
        right: 0;
    }

    .hint-aside-header {
        position: relative;
        padding: 26px 20px 20px 20px;
        height: 66px;

        .hint-aside-title {
            font-size: $base-font-size + 2;
            font-weight: $font-weight-medium;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: calc(100% - 50px);
            
            &::before {
                content: "?";
                display: inline-block;
                width: 18px;
                height: 18px;
                line-height: 18px;
                vertical-align: middle;
                background-color: $yellow-color;
                border-radius: 50%;
                margin: -3px 8px 0 0;
                text-align: center;
                font-size: $base-font-size - 2;
                font-weight: $font-weight-bold;
            }
        }

        .icon-close {
            margin: 0;
            padding: 0;
            border: 0;
            background-color: transparent;
            color: $base-font-color;
            position: absolute;
            background-color: transparent;
            right: 0;
            top: 0;
            display: block;
            width: 66px;
            height: 66px;
            line-height: 70px;
            text-align: center;
        }
    }

    .hint-aside-scroll-content {
        height: calc(100% - 80px);
        overscroll-behavior: contain;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-color: #e5e5e5 $base-font-color;

        &::-webkit-scrollbar {
            width: 11px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
            width: 11px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #e5e5e5;
            border-right: 8px solid $white-color;
            border-top: 20px solid $white-color;
            border-bottom: 20px solid $white-color;
            border-radius: $base-border-radius;

            &:hover {
                background-color: #e5e5e5;
            }

            &:active {
                background-color: #e5e5e5;
            }

            &:vertical {
                min-height: 175px;
            }
        }

        .hint-aside-content {
            padding: 0 20px;
            min-height: 100%;
            
            p {
                font-size: $base-font-size;
                line-height: $base-line-height;
                margin: 0 0 25px 0;
                
                img {
                    display: block;
                    width: 100%;
                    max-width: 100%;
                    margin: 0 0 7px 0;
                }
                
                .img-alt {
                    color: $grey-color;
                    font-size: $base-font-size - 2;
                }
                
                strong {
                    font-weight: $font-weight-medium;
                }
            }
        }
    }
}
