.box-agent-big {
    background-color: $base-color;
    color: $white-color;
    color: $white-color;
    padding: 20px;
    text-align: center;
    margin: 0 0 24px 0;

    .box-agent-big-title {
        font-size: $base-font-size + 4;
        line-height: $base-line-height + 1;
        font-weight: $font-weight-medium;
        margin: 0 0 15px 0;

        @include respond-above(md) {
            font-size: $base-font-size + 6;
            line-height: $base-line-height + 4;
        }
    }

    .box-agent-big-desc {
        margin: 0 0 30px 0;
        font-size: $base-font-size;
        line-height: $base-line-height;
    }
}
