.box-offer-list {
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 24px;
    margin: 0 0 40px 0;

    .box-offer-list-title {
        font-size: $base-font-size + 2;
        line-height: $base-line-height - 2;
        font-weight: $font-weight-medium;
        margin: 0 0 20px 0;
    }

    .box-offer-list-content {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            margin: 0 0 20px 0;

            &:last-child {
                margin: 0;
            }

            .box-offer-list-content-title {
                margin: 0 0 4px 0;
                line-height: $base-line-height - 4;
                font-size: $base-font-size;
                font-weight: $font-weight-medium;

                a {
                    display: inline-block;
                }
            }

            .box-offer-list-content-company {
                line-height: $base-line-height - 7;
                font-size: $base-font-size - 2;

                a {
                    display: inline-block;
                    color: $grey-color;

                }
            }

            .box-offer-list-content-more {
                i {
                    display: inline-block;
                    font-size: $base-font-size - 4;
                    margin: -3px 0 0 10px;
                    vertical-align: middle;
                    transform: rotate(180deg);
                }
            }
        }
    }
}
