.table-title {
    text-align: left !important;
    font-size: $base-font-size + 2 !important;
    line-height: $base-line-height !important;
    margin: 0 0 20px 0 !important;
    font-weight: $font-weight-medium !important;
}

.responsive-table-wrapper {
    &::before {
        content: "\e91a";
        font-family: "icomoon";
        display: block;
        margin: 0 0 10px 0;
        animation: swipe-left-right 2s infinite;
        position: relative;

        @include respond-above(md) {
            display: none;
        }
    }

    .responsive-table {
        margin: 0 -20px 20px 0;
        padding: 0 20px 0 0;
        white-space: nowrap;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @include respond-above(md) {
            white-space: normal;
            overflow-x: auto;
            margin: 0 0 20px 0;
            padding: 0;
        }

        table {
            font-size: $base-font-size;
            width: 100%;

            tr {

                &.disabled {
                    td {
                        opacity: 0.3;

                        a {
                            color: $base-font-color;
                        }
                    }

                }

                th {
                    background-color: $base-font-color;
                    color: $white-color;
                    padding: 10px;
                    line-height: $base-line-height - 4;
                    font-weight: $font-weight-medium;
                    vertical-align: top;

                    &.text-right {
                        text-align: right;
                    }

                    &.w-small {
                        width: 80px;
                    }
                }

                td {
                    padding: 10px;
                    line-height: $base-line-height - 4;
                    vertical-align: top;

                    &.text-right {
                        text-align: right;
                    }

                    span {
                        font-size: $base-font-size - 2;
                        color: $grey-color;
                    }

                    &.note {
                        padding: 0 10px 10px 10px;
                        font-size: $base-font-size - 2;
                        color: #5d5d5d;
                        text-align: left;
                        font-style: italic;
                    }

                    .date {
                        display: flex;
                        flex-wrap: nowrap;

                        .date-from {
                            padding-right: 5px;
                        }

                        .date-to {
                            padding-left: 5px;
                        }
                    }

                    strong {
                        font-weight: $font-weight-medium;
                    }
                }

                &.bg-grey {
                    td {
                        background-color: $grey-light-color;
                    }
                }
            }
        }
    }
}

.table-legend {
    display: flex;
    flex-wrap: wrap;

    @include respond-above(md) {
        justify-content: space-between;
    }

    .legend {
        margin: 0 0 20px 0;
        width: 100%;
        order: 1;

        @include respond-above(md) {
            width: auto;
            order: 2;
        }
    }

    .paginator {
        width: 100%;
        order: 2;

        @include respond-above(md) {
            width: auto;
            order: 1;
        }
    }
}

.table-filter {

    @include respond-above(sm) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    label {
        font-size: $base-font-size + 2;
        line-height: $base-line-height;
        margin: 0;
    }

    .custom-select {
        @include respond-above(sm) {
            width: 250px;
        }
    }
}

@keyframes swipe-left-right {

    0%,
    100% {
        left: -2px;
    }

    50% {
        left: 2px;
    }
}
