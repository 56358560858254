.work-positions-list {
    padding: 40px 0 80px 0;

    h1 {
        font-size: $base-font-size + 14;
        line-height: $base-line-height + 12;
        margin-bottom: 40px;
        text-align: center;
    }

    .masonry-list {
        --masonry-columns: 1;
        --masonry-gap: 40px;
        column-count: var(--masonry-columns);
        column-gap: var(--masonry-gap);
        
        @include respond-above(sm) {
            --masonry-columns: 2;    
        }

        
        @include respond-above(md) {
            --masonry-columns: 3;    
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                padding: 0 0 0 10px;
                
                &:first-child {
                    font-size: $base-font-size + 2;
                    font-weight: $font-weight-medium;
                    margin-bottom: 8px;
                    padding: 0;
                }

                a {
                    color: $base-font-color;
                    text-decoration: none;

                    &:hover {
                        color: $base-color;
                    }
                }
            }
        }
    }

    .masonry-list > * {
        margin-bottom: var(--masonry-gap);
        break-inside: avoid;
    }

}

@supports (grid-template-rows: masonry) {
    .work-positions-list {
        .masonry-list {
            display: grid;
            grid-template-columns: repeat(var(--masonry-columns), 1fr);
            grid-gap: var(--masonry-gap);
            grid-template-rows: masonry;
        }

        .masonry-list > * {
            margin-bottom: 0;
        }
    }

}
