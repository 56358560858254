.box-white-article {
    padding: 24px 30px;
    box-shadow: 0px 13px 33px #00000024;
    border-radius: $base-border-radius;

    @include respond-above(md) {
        display: flex;
        flex-wrap: wrap;
    }

    .cv-person {
        width: 100%;
        text-align: left !important;

        span {
            font-weight: $font-weight-light;
        }

    }

    .box-white-article-full-width {
        width: 100%;
        margin-bottom: 20px;

        .cv-person {
            margin-bottom: 30px;
            text-align: left;
        }

        .cv-info {
            font-size: $base-font-size;
            line-height: $base-line-height - 4;
            margin: 0 0 6px 0;

            span {
                color: $grey-color;
            }
        }

        .cv-date {
            font-size: $base-font-size - 2;
            line-height: $base-line-height + 2;
            color: $grey-color;
            margin: 0 0 24px 0;
            padding: 9px 0 0 0;
        }

        .box-white-article-title {
            font-size: $base-font-size + 6;
            line-height: $base-line-height + 4;
            font-weight: $font-weight-medium;
        }

        .cv-icon-list {
            list-style-type: none;
            padding: 0 !important;
            margin: 0 !important;

            li {
                margin: 0 0 12px 0 !important;

                img {
                    width: 20px !important;
                    margin: 0 8px 0 0 !important;
                    display: inline-block !important;
                }

                a {
                    color: $base-font-color;
                }
            }
        }

        &.with-cv-image {
            display: flex;
            flex-wrap: wrap;
            
            .cv-icon-list {
                width: 100%;
                @include respond-above(sm) {
                    width: calc(100% - 136px);
                }
            }
            
            .cv-image {
                display: none;
                text-align: right;
                padding: 0;
                margin: 0 0 0 20px;

                img {
                    width: 116px !important;
                    height: auto;
                    border-radius: 50%;
                    display: inline-block !important;
                    margin: 0 !important;
                }

                @include respond-above(sm) {
                    display: block;
                }
            }
        }

    }

    .box-white-article-left {
        margin: 0 0 10px 0;

        @include respond-above(sm) {
            margin: 0 0 20px 0;

        }



        .period {
            color: #5d5d5d;
            font-size: $base-font-size - 2;
            line-height: $base-line-height - 7;
            display: block;
        }

        @include respond-above(md) {
            width: 30%;
            margin: 0;
        }

        .box-white-article-title {
            font-size: $base-font-size + 6;
            line-height: $base-line-height + 4;
            font-weight: $font-weight-medium;
        }

        p {
            margin: 0 !important;
            
            strong {
                font-weight: 600!important;
            }
        }

        @include respond-above(sm) {

            p {
                margin: 0 0 10px 0 !important;
            }

        }
    }

    .box-white-article-right {
        margin: 0 0 10px 0;

        @include respond-above(md) {
            width: calc(70% - 30px);
            margin: 0 0 10px 30px;
        }

        a {
            strong {
                font-weight: $font-weight-medium;
            }
        }

        p {
            margin: 0 0 10px 0 !important;

            &:last-of-type {
                margin: 0 !important;
            }
        }

    }

    hr {
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        padding: 10px 0 0 0;
        border-top: 0;
        margin: 0 0 30px 0;


        @include respond-above(sm) {
            padding: 20px 0 0 0;
            margin: 0 0 20px 0;
        }
    }
}
