.box-search-title {
    background-color: $base-color;
    padding: 20px 0; 

    @include respond-above(md) {
        padding: 38px 0 50px 0;
    }

    .box-search-title-text {

        color: $white-color;
        font-size: $base-font-size + 8;
        line-height: $base-line-height + 15;
        letter-spacing: 0;
        font-weight: $font-weight-medium;
        margin: 0;
        text-align: left;
        padding: 0 0 0 20px;

        @include respond-above(sm) {
            text-align: center;
            padding: 0;
        }

        @include respond-above(md) {
            font-size: $base-font-size + 16;
            letter-spacing: -0.6px;
            line-height: $base-line-height + 24;
        }

        @include respond-above(lg) {
            letter-spacing: 0;
        }

        span {
            display: inline-block;
            color: $base-color;
            background-color: $white-color;
            padding: 0 15px;
            border-radius: 100px 0px 100px 100px;

            @include respond-above(md) {
                margin-right: 5px;
            }
        }

        strong {
            font-weight: $font-weight-medium;
            display: block;

            @include respond-above(md) {
                display: inline;
            }
        }
    }
}

.box-search {
    background: linear-gradient(to bottom,
            $base-color 0%,
            $base-color 45px,
            $white-color 45px,
            $white-color 100%);


    @include respond-above(lg) {
        background: linear-gradient(to bottom,
                $base-color 0%,
                $base-color 65px,
                $white-color 65px,
                $white-color 100%);

    }

    .box-search-wrapper {

        position: relative;

        .box-search-form {
            background-color: $white-color;
            margin: 0 0 20px 0;
            border-radius: $base-border-radius;
            padding: 20px 10px;
            display: block;
            box-shadow: 0px 13px 33px #00000024;
            position: relative;
            z-index: $base-z-index + 10;

            @include respond-above(md) {
                display: flex;
            }

            @include respond-above(lg) {
                padding: 40px 28px;
            }

            .form-element {
                width: auto;
                margin: 0 10px;

                @include respond-above(md) {
                    width: 40%;
                }

                @include respond-above(xl) {
                    margin: 0 12px;
                }

                &:last-child {
                    width: auto;

                    @include respond-above(md) {
                        width: 20%;
                    }
                }

                input[type="submit"] {
                    margin-top: 4px;

                    @include respond-above(md) {
                        margin-top: 24px;
                    }


                }



            }
        }

        .bee-logo {
            position: absolute;
            z-index: $base-z-index + 15;
            right: -44px;
            height: 120px;
            width: 215px;
            top: -110px;
            display: block;

            @include respond-above(sm) {
                right: 0;
            }

            @include respond-above(md) {
                height: 143px;
                width: 256px;
                top: -131px;
                right: -30px;
            }

            @include respond-above(lg) {
                right: 0;
            }
        }

    }

    .box-search-last {
        text-align: center;
        margin: 0 0 30px 0;
        font-size: $base-font-size;

        @include respond-above(md) {
            margin: 0 0 40px 0;
        }

    }

}
