.box-work-sector {
    padding: 0 0 60px 0;
    
    @include respond-above(md) {
        padding: 0 0 80px 0;    
    }

    .box-work-sector-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
        font-size: $base-font-size - 1;

        li {
            a {
                color: $base-font-color;
                font-size: $base-font-size - 2;
                
                @include respond-above(md) {
                    font-size: $base-font-size;    
                }
            }
        }
    }
}
