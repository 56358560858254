.copyright {
    background-color: #363636;
    color: $grey-color;
    font-size: $base-font-size - 1;
    line-height: $base-line-height - 2;
    padding: 19px 0;
    margin: 0;
    
    @include respond-above(xsm) {
        padding: 23px 0 22px 0;     
    }
    
    .container {
        display: block;
        
        @include respond-above(xsm) {
            display: flex;
            justify-content: space-between;
        }

        .language-menu {
            list-style-type: none;
            padding: 0;
            margin: 0 0 2px 0;
            display: flex;
            
            @include respond-above(xsm) {
                margin: 0;     
            }

            li {
                &::after {
                    content: "/";
                    color: $base-border-color;
                    display: inline-block;
                    margin: 0 5px;
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
                
                a, span {
                    text-transform: uppercase;
                }
                
                a {
                    color: $base-border-color;
                }
            }
        }
    }

}
