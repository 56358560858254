.cke {
    border-radius: $base-border-radius;
    border: 0 !important;
}

.cke_inner {
    border-radius: $base-border-radius;
}

.cke_contents {
    border-radius: $base-border-radius;
    border: 1px solid $base-border-color !important;
}

.cke_top {
    border-radius: $base-border-radius;
    border: 1px solid $base-border-color !important;
    margin: 0 0 14px 0 !important;
    background: transparent !important;
}

.cke_toolgroup a.cke_button:last-child:after,
.cke_toolgroup a.cke_button.cke_button_disabled:hover:last-child:after {
    display: none;
}

a.cke_button {
    border-radius: 4px;
    margin: 0 4px 0 0 !important;
}

a.cke_button_off:hover,
a.cke_button_off:focus,
a.cke_button_off:active {
    background: transparent !important;
}

.cke_toolgroup {
    margin: 1px 0 6px 0 !important;
    padding-right: 0 !important;
}
