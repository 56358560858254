.lectures {
    .lectures-filter {
        margin-bottom: 4px;
    }

    .lectures-list {
        margin: 0 0 16px 0;

        .lecture {
            display: block;
            padding: 20px;
            border-radius: $base-border-radius;
            background-color: $white-color;
            box-shadow: 0px 13px 33px #00000024;
            text-decoration: none;
            margin: 0 0 24px 0;

            &:hover {
                .lecture-title {
                    color: $base-color;
                }
            }

            .lecture-image {
                display: block;
                width: 100%;
                max-width: 100%;
                border-radius: $base-border-radius;
                margin: 0 0 12px 0;
            }

            .lecture-info {
                font-size: $base-font-size - 1;
                color: $grey-color;
                line-height: $base-line-height + 2;
                margin: 0 0 8px 0;
            }

            .lecture-title {
                font-size: $base-font-size + 2;
                line-height: $base-line-height;
                font-weight: $font-weight-medium;
                color: $base-font-color;
                @include transition-with-prefix('color', '200ms');
                overflow: hidden;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                height: 60px;
                display: -webkit-box;
            }
        }
    }
    
    .paginator {
        justify-content: center;
    }
}
