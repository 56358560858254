.offer-detail-content {
    padding: 0 0 70px 0;

    .offer-detail-top {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 0 24px 0;
        align-items: center;

        .offer-detail-top-left {
            width: calc(100% - 70px);

            @include respond-above(md) {
                width: calc(100% - 95px);
            }
            
            .offer-company {
                margin: 0 0 9px 0;
            }
            .offer-statistics-info {
                margin: 0;
            }
            h1 {
                font-size: $base-font-size + 10;
                line-height: $base-line-height + 8;
                font-weight: $font-weight-medium;
                margin: 0 0 8px 0;
                position: relative;

                @include respond-above(md) {
                    font-size: $base-font-size + 14;
                    line-height: $base-line-height + 12;
                }

                span {
                    position: absolute;
                    color: $white-color;
                    background-color: $red-color;
                    font-size: $base-font-size - 2;
                    line-height: $base-line-height - 6;
                    font-weight: $font-weight-medium;
                    border-radius: $base-border-radius;
                    font-family: $font-family;
                    display: block;
                    padding: 8px;
                    width: 100px;
                    text-align: center;
                    left: 0;
                    top: -58px;
                    -webkit-animation: cssHide 10s forwards;
                    animation: cssHide 10s forwards; 

                    &::after {
                        content: "";
                        width: 14px;
                        height: 14px;
                        background-color: $red-color;
                        display: block;
                        transform: rotate(45deg);
                        left: 10px;
                        bottom: -6px;
                        position: absolute;
                    }
                }
            }

        }

        .offer-detail-top-right {
            width: 70px;

            @include respond-above(md) {
                width: 95px;
            }

            .offer-company-logo {
                margin-left: 10px;
                display: block;

                img {
                    width: 60px;

                    @include respond-above(md) {
                        width: 85px;
                    }
                }
            }
        }
    }

    .offer-detail-info {
        margin: 0 0 24px 0;
        padding: 0;
        list-style-type: none;
        border-radius: $base-border-radius;
        border: 1px solid $base-border-color;
        padding: 20px 20px 2px 20px;

        @include respond-above(sm) {
            display: flex;
            flex-wrap: wrap;
            padding: 24px 12px 4px 12px;
        }

        li {
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 18px 0;

            @include respond-above(sm) {
                width: 50%;
                padding: 0 12px;
                margin: 0 0 20px 0;
            }

            .offer-detail-info-icon {
                margin-right: 8px;

                img {
                    width: 20px;
                    display: block;
                }
            }

            .offer-detail-info-content {
                width: calc(100% - 28px);

                .offer-label {
                    font-weight: $font-weight-bold;
                    font-size: $base-font-size;
                    line-height: $base-line-height - 4;
                    margin: 0 0 4px 0;
                }

                .offer-info {
                    font-size: $base-font-size;
                    line-height: $base-line-height - 2;
                }

                .offer-link {
                    font-size: $base-font-size;
                    line-height: $base-line-height - 2;
                }

            }
        }
    }

    h2 {
        font-size: $base-font-size + 6;
        line-height: $base-line-height + 4;
        margin: 30px 0 20px 0;
        font-weight: $font-weight-medium;

        @include respond-above(sm) {
            margin: 40px 0 24px 0;
            font-size: $base-font-size + 10;
            line-height: $base-line-height + 8;
        }
    }

    p {
        padding: 0 0 0 10px;
        font-size: $base-font-size;
        line-height: $base-line-height - 2;
        margin: 0 0 24px 0;

        @include respond-above(sm) {
            line-height: $base-line-height;
        }
    }

    h3 {
        font-size: $base-font-size + 1;
        line-height: $base-line-height - 3;
        font-weight: $font-weight-medium;
        margin: 0 0 8px 0;

        @include respond-above(sm) {
            font-size: $base-font-size + 2;
            line-height: $base-line-height - 2;
        }
    }

    ul {
        margin: 0 0 40px 0;
        padding: 0 0 0 20px;

    }

    .offer-date {
        font-size: $base-font-size - 2;
        line-height: $base-line-height - 2;
        color: $grey-color;
        padding: 0 0 24px 0;
        margin: 0 0 24px 0;
        border-bottom: 1px solid $base-border-color;

        &.without-buttons {
            padding: 78px 0 24px 0;
        }

        strong {
            font-weight: $font-weight-medium;
        }
    }

    .box-offer-list {
        padding: 0 0 24px 0;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        border-bottom: 1px solid $base-border-color;
        border-radius: 0;

    }
}

.offer-detail-buttons {
    margin: 0 0 30px 0 !important;
    padding: 0 !important;
    list-style-type: none;
    display: flex;

    &.fixed-element {

        margin: 0 !important;

        li {
            .btn {
                box-shadow: 0px 12px 30px #00000033;
            }

            .btn-white {
                border-color: $white-color;

                &.icon-heart {
                    &:hover {
                        text-decoration: none;
                    }
                }

                &:hover {
                    text-decoration: underline;
                    box-shadow: 0px 12px 30px #00000033;
                }
            }
        }
    }

    li {
        margin: 0 15px 0 0;

        @include respond-above(sm) {
            margin: 0 20px 0 0;
        }


        &:last-child {
            margin: 0;
        }

        .btn {
            padding: 11px 15px;
            height: 46px;

            @include respond-above(sm) {
                height: 48px;
                padding: 11px 20px;
            }

            i {
                font-size: $base-font-size + 2;
                display: inline-block;
                vertical-align: middle;
                margin: -1px 8px 0 0;
            }

            span {
                display: none;

                @include respond-above(sm) {
                    display: inline;
                }
            }
        }

        .btn-white {
            border: 1px solid $base-border-color;
            color: $base-font-color;
            padding: 11px 15px;
            width: 46px;

            @include respond-above(sm) {
                padding: 11px 20px;
                width: auto;
            }

            &:hover {
                box-shadow: 0px 13px 33px rgb(0 0 0 / 14%);
                border-color: #fff;
                background-color: $white-color;
                color: $base-font-color;
            }
        }

        .icon-heart {
            font-size: $base-font-size + 6;
            height: 46px;
            line-height: 46px;
            padding: 0 10px;
            position: relative;
            width: 46px;

            &:hover {
                color: $base-color;

                span {
                    color: $base-font-color;
                }
            }

            &.favorite {
                &::before {
                    content: "\e906";
                    color: $base-color;
                }

                &:hover {
                    color: $base-color;

                    span {
                        color: $base-font-color;
                    }
                }
            }


            @include respond-above(sm) {
                font-size: $base-font-size + 10;
                height: 48px;
                line-height: 48px;
                width: auto;
            }

            span {
                position: absolute;
                background-color: $yellow-color;
                font-size: $base-font-size - 2;
                line-height: $base-line-height - 6;
                font-weight: $font-weight-medium;
                border-radius: $base-border-radius;
                font-family: $font-family;
                display: block;
                padding: 8px;
                width: 139px;
                text-align: center;
                right: 10px;
                top: -58px;
                -webkit-animation: cssHide 10s forwards;
                animation: cssHide 10s forwards;

                &:hover {
                    color: $base-font-color;
                }

                &::after {
                    content: "";
                    width: 14px;
                    height: 14px;
                    background-color: $yellow-color;
                    display: block;
                    transform: rotate(45deg);
                    right: 8px;
                    bottom: -6px;
                    position: absolute;
                }
            }
        }
    }
}

.detail-offer-bottom {
    padding: 0;

    .detail-offer-video {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        height: 0;
        margin: 0 0 35px 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

@keyframes cssHide {
    0% {
        opacity: 1;
        visibility: visible;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@-webkit-keyframes cssHide {
    0% {
        opacity: 1;
        visibility: visible;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}
