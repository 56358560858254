$font-family: 'Ubuntu', sans-serif;
$base-font-size: 14px;
$base-line-height: 20px;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$base-z-index: 10;
$base-font-color: #2b2b2b;
$base-color: #00b787;
$base-hover-color: #00a57a;
$base-width: 1140px;
$white-color: #fff;
$yellow-color: #ffd45e;
$yellow-hover-color: #EAC152;
$grey-color: #8b8b8b;
$grey-light-color: #f5f5f5;
$red-color: #df1e42;
$base-border-radius: 8px;
$base-border-color: #e5e5e5;
$breakpoints: (
    xs: 0,
    xsm: 480px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);
