.box-top-employers {
    margin: 0 0 60px 0;
    overflow: hidden;
    
    @include respond-above(md) {
        margin: 0 0 80px 0;    
    }

    .container {
        
        .box-top-employers-list {
            margin: 0 -2px 20px -2px;
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;

            li {
                width: calc(100% / 2 - 4px);
                margin: 0 2px 4px 2px; 
                aspect-ratio: 1 / 1 ;
                
                @include respond-above(xsm) {
                    width: calc(100% / 3 - 4px);        
                }
                
                @include respond-above(md) {
                     width: calc(100% / 4 - 4px);
                }
                
                @include respond-above(lg) {
                    width: calc(100% / 6 - 4px);   
                }
                
                a {
                    display: block;
                    border-radius: $base-border-radius;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        &::before {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            content: "";
                            border-radius: $base-border-radius;
                            box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1);
                            z-index: $base-z-index;
                        }
                    }

                    img {
                        width: 80px;
                        height: auto;
                        
                        @include respond-above(md) {
                            width: 100px;     
                        }

                    }
                }
            }
        }
        .box-top-employers-more-link {
            i {
                font-size: $base-font-size - 4;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                margin-top: -4px;
                transform: rotate(180deg);
            }
        }
    }
}
