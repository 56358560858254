.listing-filter {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px 10px -5px;

    .form-element {
        width: calc(100% - 10px);
        margin: 0 5px 10px 5px;

        @include respond-above(xsm) {
            width: calc(50% - 10px);
        }

        @include respond-above(md) {
            width: calc(25% - 10px);
        }
    }
}

.listing-info {
    display: flex;
    justify-content: center;
    margin: 0 0 24px 0;

    .listing-info-label {
        font-weight: $font-weight-medium;
        margin: 0 10px;

        a,
        span {
            color: $base-color;

            &.grey-color {
                color: #b7b7b7;
            }
        }
    }
}

.listing-content {
    margin: 0;
}

.listing-note {
    color: #5d5d5d;
    margin: -20px 0 40px 0;
}

.listing {
    .paginator {
        justify-content: center;
    }

    .offer {
        background-color: $white-color;
        box-shadow: 0px 13px 33px #00000024;
        border-radius: $base-border-radius;
        padding: 20px;
        margin: 0 0 20px 0;
        position: relative;

        .icon-close {
            font-size: $base-font-size;
            background-color: transparent;
            border: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: $base-font-color;
        }

        &:last-of-type {
            margin: 0 0 40px 0;
        }

        &.inactive {
            color: #b7b7b7;

            a {
                color: #b7b7b7;
            }

            .offer-info {
                color: #b7b7b7;
            }

            .offer-menu-links {
                .offer-count {
                    background-color: #b7b7b7;
                    color: $white-color;

                    &.yellow {
                        background-color: #b7b7b7;
                        color: $white-color;
                    }
                }

                .icon-heart-full {
                    color: #b7b7b7;
                }
            }

            .offer-footer {
                .offer-menu {
                    li {
                        .red {
                            color: #b7b7b7;
                        }
                    }
                }

                .offer-footer-info {
                    .icon-info {
                        background-color: #b7b7b7;
                    }

                    .top {
                        background-color: #b7b7b7;
                    }

                    .statistics-link {
                        .icon-eye {
                            color: #b7b7b7;
                        }
                    }
                }
            }

        }

        .offer-title {
            text-align: left;
            margin: 0 50px 4px 0 !important;
        }

        .offer-company-info {
            color: $grey-color;
            margin: 0 0 20px 0;
        }

        .offer-info {
            margin: 0;

            .cv-progress-bar {
                max-width: 240px;
                padding: 10px 0 0 0;
                margin: 0;
            }

            strong {
                font-weight: $font-weight-medium;
            }
        }

        .listing-form {
            display: flex;
            padding: 20px 0 0 0;
            margin: 0 0 -10px 0;
            flex-wrap: wrap;

            .form-element {
                align-items: center;
                margin: 0 20px 10px 0;

                label {
                    width: auto;
                    margin: 0 8px 0 0;
                    min-width: 0;
                }

                input[type=text],
                input[type=email],
                input[type=password],
                input[type=tel],
                input[type=date],
                input[type=number],
                input[type=url] {
                    padding: 5px 8px;
                    height: 36px;
                    text-align: center;
                    width: 48px;
                }
            }

        }

        .offer-menu-links {
            padding: 0;
            margin: 20px 0 0 0;
            list-style-type: none;

            @include respond-above(md) {
                display: flex;
            }


            li {

                position: relative;
                margin: 0 0 5px 0;

                @include respond-above(md) {
                    padding: 0 10px 0 0;
                    margin: 0 10px 0 0;
                }

                &::after {

                    @include respond-above(md) {
                        position: absolute;
                        right: 0;
                        top: calc(50% - 8px);
                        content: "";
                        width: 1px;
                        height: 16px;
                        background-color: #5d5d5d;
                    }

                }

                &:last-child {
                    margin: 0;
                    padding: 0;

                    &::after {
                        display: none;
                    }
                }
            }

            .offer-count {
                display: inline-block;
                height: 24px;
                line-height: 24px;
                background-color: $base-color;
                border-radius: $base-border-radius;
                color: $white-color;
                text-align: center;
                font-size: $base-font-size;
                font-weight: $font-weight-medium;
                padding: 0 6px;
                vertical-align: middle;
                margin: -3px 0 0 5px;

                &.yellow {
                    background-color: $yellow-color;
                    color: $base-font-color;
                }
            }

            .icon-heart-full {
                color: $base-color;
                font-size: $base-font-size + 2;
                display: inline-block;
                vertical-align: middle;
                margin: -3px 5px 0 0;
            }
        }

        .offer-footer {
            margin: 20px 0 0 0;

            @include respond-above(sm) {
                display: flex;
                justify-content: space-between;
            }

            .offer-menu {
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;

                @include respond-above(sm) {
                    margin: 0;
                }

                li {
                    margin: 0 15px 10px 0;

                    @include respond-above(sm) {
                        margin: 0 15px 0 0;

                        &:last-child {
                            margin: 0;
                        }
                    }

                    &:last-child {
                        margin: 0;
                    }

                    .red {
                        color: $red-color;
                    }

                    .active {
                        color: $base-color;
                    }

                    span {
                        display: inline-block;
                        background-color: $red-color;
                        color: $white-color;
                        border-radius: $base-border-radius;
                        height: 20px;
                        line-height: 20px;
                        padding: 0 6px;
                        font-weight: $font-weight-medium;
                        font-size: $base-font-size - 3;
                        vertical-align: middle;
                        margin: -1px 0 0 0;
                    }
                }
            }

            .offer-footer-info {
                margin: 20px 0 0 0;

                @include respond-above(sm) {
                    margin: 0;
                }

                .icon-info {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    display: inline-block;
                    border-radius: 50%;
                    text-align: center;
                    background-color: #2E76D8;
                    color: $white-color;
                    font-size: $base-font-size - 6;
                    margin: -1px 3px 0 0;
                    vertical-align: middle;
                }

                .top {
                    display: inline-block;
                    height: 20px;
                    line-height: 20px;
                    background-color: $red-color;
                    color: $white-color;
                    text-transform: uppercase;
                    font-size: $base-font-size - 3;
                    font-weight: $font-weight-medium;
                    padding: 0 6px;
                    border-radius: $base-border-radius;
                    margin: -1px 10px 0 0;
                    vertical-align: middle;
                }

                .statistics-link {
                    display: inline-block;
                    vertical-align: middle;
                    margin: -2px 0 0 0;

                    strong {
                        font-weight: $font-weight-medium;
                    }

                    .icon-eye {
                        display: inline-block;
                        vertical-align: middle;
                        font-size: $base-font-size - 1;
                        color: $base-color;
                        margin: -3px 5px 0 0;
                    }
                }
            }
        }
    }
}
