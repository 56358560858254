@mixin transition-with-prefix($name, $value) {
    @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
        #{$vendor}transition: #{$name} #{$value};
    }
}

@mixin icomoon ($name, $path, $icons) {
    @font-face {
        font-family: "#{$name}";
        src: url("#{$path}.eot");
        src: url("#{$path}.eot#iefix") format('embedded-opentype'),
        url("#{$path}.ttf") format('truetype'),
        url("#{$path}.woff") format('woff'),
        url("#{$path}.svg#icomoon") format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    %icomoon-opts {
        font-family: "#{$name}" !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    @each $icon,
    $content in $icons {
        .icon-#{$icon} {
            @extend %icomoon-opts;

            &:before {
                content: $content;
            }
        }
    }
}

@mixin background-2x($path, $ext: "png", $w: auto, $h: auto, $pos: left top, $repeat: no-repeat) {

  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";

  background-image: url("#{$at1x_path}");
  background-size: $w $h;
  background-position: $pos;
  background-repeat: $repeat;

  @media all and (-webkit-min-device-pixel-ratio : 1.5),
  all and (-o-min-device-pixel-ratio: 3/2),
  all and (min--moz-device-pixel-ratio: 1.5),
  all and (min-device-pixel-ratio: 1.5) {
    background-image: url("#{$at2x_path}"); 
  }
}

@mixin vendor-prefix($name, $value) {
    @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
        #{$vendor}#{$name}: #{$value};
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }

    &:-moz-placeholder {
        @content
    }

    &::-moz-placeholder {
        @content
    }

    &:-ms-input-placeholder {
        @content
    }
}

@mixin respond-above($breakpoint) {


    @if map-has-key($breakpoints, $breakpoint) {


        $breakpoint-value: map-get($breakpoints, $breakpoint);


        @media (min-width: $breakpoint-value) {
            @content;
        }


    }

    @else {


        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}


@mixin respond-below($breakpoint) {


    @if map-has-key($breakpoints, $breakpoint) {


        $breakpoint-value: map-get($breakpoints, $breakpoint);


        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }


    }

    @else {


        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}



@mixin respond-between($lower, $upper) {


    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {


        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);

        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }


    }

    @else {


        @if (map-has-key($breakpoints, $lower)==false) {

            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        @if (map-has-key($breakpoints, $upper)==false) {

            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}
