.scroll-steps-aside {
    display: none;
    
    @include respond-above(xl) {
        width: 200px;
        margin-right: 91px;    
        display: block;
    }

    .scroll-steps {
        margin: 0 0 20px 0;
        padding: 0;
        list-style-type: none;
        position: sticky;
        top: 187px;

        li {
            counter-increment: item;
            border-left: 4px solid $base-border-color;
            height: 60px;
            position: relative;

            &:before {
                content: counter(item);
                position: absolute;
                left: -14px;
                top: calc(50% - 12px);
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 20px;
                font-weight: $font-weight-medium;
                font-size: $base-font-size - 2;
                color: $white-color;
                background-color: #b7b7b7;
                border-radius: 50%;
                border: 2px solid $white-color;
                z-index: $base-z-index;
            }

            a {
                display: block;
                height: 60px;
                width: 100%;
                line-height: 60px;
                font-size: $base-font-size - 2;
                color: #b7b7b7;
                padding: 0 0 0 15px;

                &:hover {
                    text-decoration: none;

                }
            }

            &.active {
                border-color: $base-color;

                &:before {
                    background-color: $base-color;
                }

                a {
                    color: $base-font-color;
                }
            }
        }
    }
}
