.menu-aside {
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-color: $base-font-color;
    right: -100%;
    top: 0;
    color: $white-color;
    z-index: $base-z-index + 30;
    @include transition-with-prefix('right', '200ms');

    &.active {
        right: 0;
    }

    .menu-aside-header {
        position: relative;
        justify-content: space-between;
        padding: 16px 20px 16px 20px;
        height: 60px;

        .logo {
            font-size: $base-font-size + 14;
            display: inline-block;
            height: 28px;
            line-height: 28px;

            &::before {
                content: "\e909";
                font-family: "icomoon";
                display: inline-block;
            }
        }

        .icon-close {
            margin: 0;
            padding: 0;
            border: 0;
            background-color: transparent;
            color: $white-color;
            position: absolute;
            background-color: transparent;
            right: 0;
            top: 0;
            display: block;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
        }
    }

    .menu-aside-scroll-content {
        height: calc(100% - 80px);
        overscroll-behavior: contain;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-color: #1c1c1c $base-font-color;

        &::-webkit-scrollbar {
            width: 11px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
            width: 11px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #1c1c1c;
            border-right: 8px solid $base-font-color;
            border-top: 20px solid $base-font-color;
            border-bottom: 20px solid $base-font-color;
            border-radius: $base-border-radius;

            &:hover {
                background-color: #1c1c1c;
            }

            &:active {
                background-color: #1c1c1c;
            }

            &:vertical {
                min-height: 175px;
            }
        }

        .menu-aside-content {
            padding: 0 20px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            min-height: 100%;

            .menu {
                padding: 0;
                margin: 0 0 8px 0;
                width: 100%;
                list-style-type: none;
                text-align: center;

                li {
                    margin: 0 0 24px 0;

                    a {
                        font-size: $base-font-size + 4;
                        color: $white-color;
                        font-weight: $font-weight-medium;
                    }
                }
            }

            .menu-aside-login-buttons {
                padding: 0;
                margin: 0;
                list-style-type: none;
                text-align: center;
                width: 100%;

                li {
                    margin: 0 0 20px 0;

                    .btn {
                        font-size: $base-font-size + 4;
                        padding: 12px 13px;
                        width: 190px;

                        &.btn-white-bordered {
                            border-color: $grey-color;
                        }
                    }
                }
            }

            .user-submenu {
                width: 100%;
                max-width: 500px;
                padding: 0;
                margin: 0 auto;
                list-style-type: none;

                li {
                    margin: 0 0 16px 0;

                    &.active {
                        a {
                            color: $grey-color;
                        }
                    }

                    a {
                        line-height: $base-line-height;
                        font-size: $base-font-size + 4;
                        color: $white-color;
                        font-weight: $font-weight-medium;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .number {
                            display: inline-block;
                            font-size: $base-font-size - 3;
                            height: 16px;
                            line-height: $base-line-height - 4;
                            background-color: $base-color;
                            color: $white-color;
                            border-radius: 100px;
                            padding: 0 7px;
                            vertical-align: middle;
                            margin: 0;
                            float: right;
                        }
                    }

                    &.logout {
                        border-top: 1px solid #363636;
                        margin-bottom: 0;
                        padding-top: 16px;

                        a {
                            font-weight: $font-weight-normal;
                            color: $red-color;
                        }
                    }

                    &.has-submenu {
                        button {
                            margin: 0 0 16px 0;
                            padding: 0 20px 0 0;
                            border: 0;
                            background-color: transparent;
                            font-weight: $font-weight-medium;
                            display: block;
                            width: 100%;
                            text-align: left;
                            color: $white-color;
                            position: relative;
                            font-size: $base-font-size + 4;
                            line-height: $base-line-height;

                            &:hover {
                                text-decoration: underline;
                            }

                            &::after {
                                content: "\e900";
                                font-family: "icomoon";
                                position: absolute;
                                right: 0;
                                top: 0;
                                font-size: $base-font-size - 6;
                            }
                        }

                        &.opened {
                            button {
                                &::after {
                                    transform: rotate(-180deg);
                                }
                            }

                            ul {
                                display: block;
                            }
                        }

                        ul {
                            margin: 0 0 0 10px;
                            padding: 0;
                            list-style-type: none;
                            display: none;

                            li {
                                margin: 0 0 10px 0;

                                a {
                                    font-weight: $font-weight-normal;
                                    font-size: $base-font-size + 2;

                                    .number {
                                        display: inline-block;
                                        font-size: $base-font-size - 2;
                                        height: 18px;
                                        line-height: $base-line-height - 2;
                                        background-color: $base-color;
                                        color: $white-color;
                                        border-radius: 100px;
                                        padding: 0 7px;
                                        vertical-align: middle;
                                        margin: 0;
                                        float: right;

                                        &.red {
                                            background-color: $red-color;
                                        }
                                    }
                                }

                                .contact-info {
                                    color: $white-color;
                                    margin: 0 0 15px 0;
                                    font-size: $base-font-size + 2;

                                    a {
                                        color: $base-color;
                                        display: inline;
                                        font-weight: $font-weight-normal;
                                    }

                                }

                                .note {
                                    text-align: left;
                                }
                            }
                        }
                    }

                }
            }

            .user-info {
                text-align: center;
                width: 100%;
                margin: 0 0 40px 0;

                &:hover {
                    text-decoration: none;

                    .user-info-name {
                        text-decoration: underline;
                    }
                }

                .user-info-link {
                    .user-info-image {
                        width: 80px;
                        display: inline-block;
                        border-radius: 50%;
                        margin: 0 0 10px 0;
                    }

                    .user-info-avatar {
                        width: 80px;
                        display: inline-block;
                        line-height: $base-line-height + 60;
                        height: 80px;
                        text-align: center;
                        background-color: $white-color;
                        border-radius: 50%;
                        margin-bottom: 10px;
                        color: $base-font-color;
                        font-size: $base-font-size + 6;
                        font-weight: $font-weight-medium;
                    }

                    .user-info-name {
                        display: block;
                        font-size: $base-font-size + 4;
                        line-height: $base-line-height + 2;
                        font-weight: $font-weight-medium;
                        color: $white-color;
                    }
                }
            }
        }
    }
}
