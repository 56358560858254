hr {
    margin: 0 0 40px 0;
    border-top: 1px solid $base-border-color;
    opacity: 1;
}

.btn-text {
    color: $base-color;
    font-size: $base-font-size;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;

    &.btn-delete {
        color: $red-color;
    }
}

.note {
    text-align: center;
    color: $grey-color;
    font-size: $base-font-size - 2;
    line-height: $base-line-height - 2;
}

.selected-values {
    padding: 4px 0 0 0;
    display: flex;
    flex-wrap: wrap;

    span {
        background-color: $yellow-color;
        line-height: $base-line-height - 7;
        font-size: $base-font-size - 2;
        font-weight: $font-weight-medium;
        padding: 8px 25px 8px 8px;
        border-radius: $base-border-radius;
        margin: 0 5px 5px 0;
        position: relative;
        cursor: pointer;
        @include transition-with-prefix('background-color', '200ms');

        &::before {
            content: "\e90f";
            font-family: "icomoon";
            position: absolute;
            right: 8px;
            top: 8px;
            font-size: $base-font-size - 5;
        }

        &:hover {
            background-color: $yellow-hover-color;
        }
    }
}

.label-desc {
    font-size: $base-font-size - 1;
    line-height: $base-line-height - 2;
    color: $grey-color;
    margin: 0 0 20px 0;
}

.form-element {
    margin: 0 0 20px 0;
    
    &.max-558 {
        max-width: 558px;
        margin: 0 auto 20px auto;
    }

    .photo-upload {
        width: 200px;
        height: 200px;
        display: flex;
        border-radius: 50%;
        border: 1px dashed #b7b7b7;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;

        .custom-input-file {
            margin: 0;

            label {
                height: 33px;
                line-height: 31px;
                margin: 0 0 10px 0 !important;
                font-size: $base-font-size - 2;
                min-width: 0 !important;
            }
        }

        .photo-upload-desc {
            font-size: $base-font-size - 2;
            line-height: $base-line-height - 5;
            width: 100%;
            display: block;
        }

    }

    .photo-image {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin: 0 auto 8px auto;

        img {
            display: block;
            width: 100%;
            border-radius: 50%;
            object-fit: cover;
            aspect-ratio: 1/1;
        }

    }

    .photo-image-delete {
        padding: 0;
        border: 0;
        background: none;
        color: $red-color;
        font-size: $base-font-size - 2;
    }

    &.driver-licence {
        .custom-checkbox {

            input[type="checkbox"] {

                & + label {
                    color: $base-font-color;
                    font-weight: $font-weight-medium;
                    font-size: $base-font-size;
                    display: inline-block;
                }
            }

            i {
                margin-left: 8px;
            }

            .icon-am {
                font-size: $base-font-size;
            }

            .icon-a1 {
                font-size: $base-font-size;
            }

            .icon-a2 {
                font-size: $base-font-size;
            }

            .icon-a {
                font-size: $base-font-size;
            }

            .icon-b1 {
                font-size: $base-font-size + 4;
            }

            .icon-b {
                font-size: $base-font-size;
            }

            .icon-c1 {
                font-size: $base-font-size + 4;
            }

            .icon-c {
                font-size: $base-font-size + 4;
            }

            .icon-d1 {
                font-size: $base-font-size + 4;
            }

            .icon-d {
                font-size: $base-font-size + 4;
            }

            .icon-be {
                font-size: $base-font-size;
            }

            .icon-c1e {
                font-size: $base-font-size + 4;
            }

            .icon-ce {
                font-size: $base-font-size + 4;
            }

            .icon-d1e {
                font-size: $base-font-size + 4;
            }

            .icon-de {
                font-size: $base-font-size + 4;
            }

            .icon-t {
                font-size: $base-font-size + 4;
            }
        }
    }

    &.switch-text-right {
        margin: 0 0 25px 0;

        .switch {
            justify-content: flex-start;

            .switch-slider {
                margin-right: 10px !important;
            }
        }
    }
    
    &.switch-text-center {
        margin: 0 0 25px 0;
        
        .switch {
            justify-content: center;
            
            .switch-slider {
                margin: 0 10px 0 0!important;
            }
            .switch-label {
                width: auto;
                max-width: calc(100% - 46px);
                text-align: left;
            }
        }
    }

    .error-label {
        display: block;
        color: $red-color;
        font-size: $base-font-size - 2;
        padding: 4px 0 0 17px;
    }

    .info-label {
        display: block;
        color: #5d5d5d;
        font-size: $base-font-size - 2;
        padding: 4px 0 0 17px;
    }

    &.label-inline {
        display: flex;

        label {
            width: 136px;
            margin-top: 8px;
            margin-right: 10px;
            line-height: $base-line-height;
            min-width: 100px;
        }
    }

    &.radio-inline {

        label {
            width: 100%;
        }

        @include respond-above(sm) {
            display: flex;
            flex-wrap: wrap;
        }

        .custom-radio {
            margin: 0 0 20px 0;

            @include respond-above(sm) {
                margin: 0 40px 0 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .custom-input-file {
        margin: 0 0 2rem 0;

        .selected-values {
            padding: 0;
            margin: -5px 0 5px 0;
        }

        label {
            width: auto !important;
            display: inline-block;
            margin: 0 0 15px 0 !important;
            height: 36px;
            line-height: 34px;
            padding: 0 14px !important;
        }

        p {
            margin: 0;
        }

        .note {
            text-align: left;
        }
    }

    .custom-checkbox {

        &.error {
            input[type="checkbox"] {
                & + label:before {
                    border-color: $red-color;
                }
            }

            .error-label {
                padding: 4px 0 0 30px;
            }
        }

        .btn-label-more {
            display: inline-block;
            font-size: $base-font-size - 1;
            margin: 5px 0 0 30px;
        }

        input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            margin: 0;

            & + label {
                position: relative;
                cursor: pointer;
                padding: 0;
                margin: 0;
                font-size: $base-font-size - 1;
                color: $grey-color;
                line-height: $base-line-height - 2;
                font-weight: $font-weight-normal;
                padding: 0 0 0 30px !important;

                span {
                    display: block;
                    font-size: $base-font-size - 2;
                }

                strong {
                    color: $base-font-color;
                    font-weight: $font-weight-normal;
                }
            }


            & + label:before {
                content: '';
                margin: 0;
                padding: 0;
                display: block;
                width: 20px;
                height: 20px;
                border-radius: 4px;
                border: 1px solid $base-border-color;
                background: $white-color;
                position: absolute;
                left: 0;

            }

            &:hover + label:before {
                background: $white-color;
            }

            &:checked + label:before {
                background: $base-color;
                border-color: $base-color;
            }

            &:disabled + label {
                opacity: 0.5;
                cursor: auto;
            }

            &:disabled + label:before {
                box-shadow: none;
                background: $white-color;
            }

            &:checked + label:after {
                content: "\e918";
                font-family: "icomoon";
                font-size: $base-font-size - 5;
                position: absolute;
                color: $white-color;
                left: 4px;
                top: 0;
            }
            
            &:required + label {
                padding: 0 0 0 40px!important;
            }
            
            &:required + label .required {
                color: $red-color;
                font-size: $base-font-size;
                position: absolute;
                left: 29px;
                top: 0;
            }
        }
    }

    .custom-radio {

        &.error {
            input[type="radio"] {
                & + label:before {
                    border-color: $red-color;
                }
            }

            .error-label {
                padding: 4px 0 0 30px;
            }
        }

        input[type="radio"] {
            position: absolute;
            opacity: 0;
            margin: 0;

            & + label {
                position: relative;
                cursor: pointer;
                padding: 0;
                margin: 0;
                font-size: $base-font-size - 1;
                color: $grey-color;
                line-height: $base-line-height - 2;
                font-weight: $font-weight-normal;
                padding: 0 0 0 30px;
                width: auto;
                display: inline-block;
            }


            & + label:before {
                content: '';
                margin: 0;
                padding: 0;
                display: block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid $base-border-color;
                background: $white-color;
                position: absolute;
                left: 0;
                top: -1px;

            }

            &:hover + label:before {
                background: $white-color;
            }

            &:disabled + label {
                opacity: 0.5;
                cursor: auto;
            }

            &:disabled + label:before {
                box-shadow: none;
                background: $white-color;
            }

            &:checked + label:after {
                content: "";
                width: 12px;
                height: 12px;
                position: absolute;
                border-radius: 50%;
                background-color: $base-color;
                left: 4px;
                top: 3px;
            }
        }
    }

    label {
        display: inline-block;
        font-size: $base-font-size;
        line-height: $base-line-height - 6;
        margin: 0 0 8px 15px;
        font-weight: $font-weight-medium;

        &.required {
            &::after {
                content: "*";
                color: $red-color;
                margin-left: 3px;
            }
        }

        &.with-info-label {
            &::after {
                content: "*";
                color: #5d5d5d;
                margin-left: 3px;
            }
        }

        @include respond-above(md) {
            line-height: $base-line-height - 4;
        }
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="date"],
    input[type="number"],
    input[type="url"] {
        border: 1px solid $base-border-color;
        border-radius: $base-border-radius;
        font-size: $base-font-size;
        color: $base-font-color;
        width: 100%;
        outline: none;
        padding: 11px 16px;
        display: block;
        height: 44px;
        -webkit-appearance: none;
        background-color: $white-color;
        text-align: left;

        @include placeholder {
            color: $grey-color;
        }

        @include respond-above(md) {
            padding: 13px 16px;
            height: 48px;
        }

        &.error {
            border-color: $red-color;
        }

        &[readonly] {
            background-color: $grey-light-color;
            font-weight: $font-weight-medium;
        }
    }

    input[type="date"] {
        position: relative;

        &::-webkit-calendar-picker-indicator {
            color: transparent;
            background: none;
            z-index: 1;
        }

        &::-webkit-date-and-time-value {
            text-align: left;
        }

        &::before {
            color: transparent;
            background: none;
            display: block;
            font-family: 'icomoon';
            content: '\e919';
            font-size: $base-font-size + 2;
            position: absolute;
            top: 12px;
            right: 18px;
            color: $base-border-color;
        }
    }

    textarea {
        border: 1px solid $base-border-color;
        border-radius: $base-border-radius;
        font-size: $base-font-size;
        color: $base-font-color;
        width: 100%;
        outline: none;
        padding: 11px 16px;
        display: block;
        height: 200px;
        resize: none;
        overscroll-behavior: contain;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-color: #e5e5e5 $white-color;

        &.error {
            border-color: $red-color;
        }

        &::-webkit-scrollbar {
            width: 11px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
            width: 11px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #e5e5e5;
            border-right: 8px solid $white-color;
            border-top: 20px solid $white-color;
            border-bottom: 20px solid $white-color;
            border-radius: $base-border-radius;

            &:hover {
                background-color: #e5e5e5;
            }

            &:active {
                background-color: #e5e5e5;
            }

            &:vertical {
                min-height: 175px;
            }
        }
    }
    
    .textarea-wrapper {
        position: relative;
        .btn {
            position: absolute;
            left: 14px;
            bottom: 14px;
            margin: 0!important;
        }
    }

    .input-password {
        position: relative;

        input[type="text"],
        input[type="password"] {
            padding: 11px 48px 11px 16px;
        }

        .icon-eye {
            margin: 0;
            padding: 0;
            border: 0;
            height: 44px;
            line-height: $base-line-height + 24;
            width: 44px;
            background: transparent;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            color: $base-border-color;
            font-size: $base-font-size - 2;

            @include respond-above(md) {
                height: 48px;
                line-height: $base-line-height + 28;
                width: 48px;
            }
        }
    }

    input[type="submit"],
    .btn {
        border: 1px solid $base-color;
        border-radius: $base-border-radius;
        background-color: $base-color;
        color: $white-color;
        text-align: center;
        width: 100%;
        display: block;
        font-weight: $font-weight-medium;
        padding: 11px 16px;
        font-size: $base-font-size;

        @include respond-above(md) {
            padding: 13px 16px;
        }

        @include transition-with-prefix('all', '200ms');

        &:hover {
            border-color: $base-hover-color;
            background-color: $base-hover-color;
        }
        
        &.btn-gradient {
            background: transparent linear-gradient(323deg, #FFD45E 0%, #07B787 79%, #06A57A 100%) 0% 0% no-repeat padding-box;
            border: 0;
        }
        
        &.btn-centered {
            margin: 0 auto;
            width: auto;
            min-width: 190px;
        }
    }
    
    .custom-search {
        position: relative;
        input {
            padding-right: 40px;
            @include placeholder {
                color: $base-font-color;
            }
        }
        .icon-magnifier {
            position: absolute;
            right: 0;
            top: 0;
            background: transparent;
            border: 0;
            display: block;
            height: 48px;
            line-height: 48px;
            width: 40px;
            text-align: center;
            font-size: $base-font-size - 2;
        }
    }

    .custom-select {
        border-radius: $base-border-radius;
        position: relative;
        height: 44px;
        
        .custom-select-placeholder {
            color: $grey-color;
        }
        
        option {
            color: $base-font-color;
        }

        &.disabled {
            background-color: $grey-light-color;

            &::after {
                color: #b7b7b7;
            }

        }

        @include respond-above(md) {
            height: 48px;
        }

        &.error {
            select {
                border-color: $red-color;
            }
        }

        select {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: $base-z-index;
            appearance: none;
            background-color: transparent;
            padding: 0 40px 0 16px;
            height: 44px;
            outline: none;
            border-radius: $base-border-radius;
            color: $base-font-color;
            cursor: pointer;
            border: 1px solid $base-border-color;

            @include respond-above(md) {
                height: 48px;
            }

            &:focus {
                border-color: $base-font-color;
            }

            &:disabled {
                color: #b7b7b7;
            }
        }

        &::after {
            font-size: $base-font-size - 8;
            content: "\e900";
            font-family: "icomoon";
            position: absolute;
            right: 16px;
            top: 13px;
        }
    }

    .switch {
        display: flex;
        justify-content: space-between;
        padding: 10px 0 0 0;

        .switch-label {
            font-size: $base-font-size!important;
            line-height: $base-line-height!important;
            width: calc(100% - 46px);
            margin: 0!important;
            font-weight: $font-weight-normal!important;
        }

        .switch-slider {
            position: relative;
            display: inline-block;
            width: 36px;
            height: 20px;
            margin: 0 !important;

            input {
                opacity: 0;
                width: 0;
                height: 0;

                &:checked + span {
                    background-color: $base-color;
                }

                &:focus + span {
                    box-shadow: 0 0 1px $base-color;
                }

                &:checked + span:before {
                    -webkit-transform: translateX(16px);
                    -ms-transform: translateX(16px);
                    transform: translateX(16px);
                }
            }

            span {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $base-border-color;
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 15px;

                &::before {
                    position: absolute;
                    content: "";
                    height: 16px;
                    width: 16px;
                    left: 2px;
                    bottom: 2px;
                    background-color: $white-color;
                    -webkit-transition: .4s;
                    transition: .4s;
                    border-radius: 50%;
                }
            }
        }
    }

    .special-select-wrapper {
        position: relative;
        margin: 0 0 16px 0;

        @include respond-above(md) {
            margin: 0;
        }

        .special-select {
            border: 1px solid $base-border-color;
            border-radius: $base-border-radius;
            position: relative;

            &::before {
                content: "\e900";
                font-family: "icomoon";
                position: absolute;
                right: 16px;
                top: 12px;
                font-size: $base-font-size - 8;
                z-index: $base-z-index;

                @include respond-above(md) {
                    top: 14px;
                }
            }

            input[type="text"] {
                height: 44px;
                background-color: transparent;
                width: 100%;
                border-radius: $base-border-radius;
                border: 0;
                padding: 0 40px 0 15px;
                z-index: $base-z-index + 10;
                position: relative;
                font-size: $base-font-size;
                color: $base-font-color;
                outline: none;

                @include respond-above(md) {
                    height: 46px;

                }

                @include placeholder {
                    color: $grey-color;
                }

            }
        }

        .special-select-list {
            position: absolute;
            left: 0;
            top: 48px;
            z-index: $base-z-index + 20;
            width: 100%;
            height: 284px;
            border-radius: $base-border-radius;
            background-color: $white-color;
            box-shadow: 0px 13px 33px #00000024;
            padding: 6px 0 0 0;
            display: none;
            margin: 0;
            list-style-type: none;
            overscroll-behavior: contain;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            scrollbar-color: #e5e5e5 $white-color;

            @include respond-above(md) {
                top: 52px;
            }

            &::-webkit-scrollbar {
                width: 11px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
                width: 11px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #e5e5e5;
                border-right: 8px solid $white-color;
                border-top: 20px solid $white-color;
                border-bottom: 20px solid $white-color;
                border-radius: $base-border-radius;

                &:hover {
                    background-color: #e5e5e5;
                }

                &:active {
                    background-color: #e5e5e5;
                }

                &:vertical {
                    min-height: 175px;
                }
            }

            li {
                font-size: $base-font-size - 2;
                color: $grey-color;
                line-height: $base-line-height - 2;

                &.hidden {
                    display: none;
                }

                &.selected {
                    background: #e9e9e9;
                }

                @include transition-with-prefix('background-color', '200ms');

                &:hover {
                    background-color: $grey-light-color;
                    cursor: pointer;
                }

                &.hover {
                    background-color: $grey-light-color;
                    cursor: pointer;
                }

                &.level {
                    padding: 6px 36px;
                    color: $base-font-color;
                    font-size: $base-font-size;
                    font-weight: $font-weight-medium;
                }

                &.keyword {
                    padding: 6px 16px;

                    span {
                        display: block;
                        color: $base-font-color;
                        font-size: $base-font-size;
                        font-weight: $font-weight-medium;
                    }
                }

                &.first {
                    padding: 5px 16px;

                    span {
                        font-size: $base-font-size;
                        color: $base-color;
                        font-weight: $font-weight-medium;
                    }

                }

                &.second {
                    padding: 6px 26px;

                    span {
                        display: block;
                        color: $base-font-color;
                        font-size: $base-font-size;
                        font-weight: $font-weight-medium;
                    }

                    &:hover {
                        background-color: $grey-light-color;
                        cursor: pointer;
                    }
                }

                &.third {
                    padding: 6px 36px;
                    color: $base-font-color;
                    font-size: $base-font-size;
                    font-weight: $font-weight-medium;

                    span {

                        span {
                            font-weight: $font-weight-normal;
                            text-transform: lowercase;
                        }

                    }

                }

                &.fourth {
                    padding: 6px 46px;
                    color: $base-font-color;
                    font-size: $base-font-size;
                    font-weight: $font-weight-medium;

                    &:hover {
                        background-color: $grey-light-color;
                        cursor: pointer;
                    }
                }

            }
        }

        &.active {

            .special-select {
                border: 1px solid $base-font-color;
            }

            .special-select-list {
                display: block;
            }
        }
    }
}
