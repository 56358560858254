.sortable:not(.ui-sortable-disabled) {
    .ui-state-default {
        padding: 0 0 20px 0;

        .box-white-form {
            &.align-start {
                position: relative;
                padding: 24px 56px 24px 24px;
                margin: 0;
                cursor: move;
                user-select: none;

                &:after {
                    content: "\e92e";
                    font-family: "icomoon";
                    position: absolute;
                    right: 20px;
                    top: calc(50% - 10px);
                    font-size: $base-font-size + 2;
                    color: $grey-color;
                }
            }
        }
    }
}

.sortable {
    .ui-state-default {
        border: 0;
        background: $white-color;
    }
}

.box-white-form {
    padding: 24px;
    box-shadow: 0px 13px 33px #00000024;
    border-radius: $base-border-radius;
    margin: 0 0 20px 0;
    text-align: center;
    color: $base-font-color;

    .work_scope {
        overflow: hidden;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        max-height: 97px;
        display: -webkit-box;
    }

    @include respond-above(sm) {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        align-items: center;
    }

    &.full-width {
        display: block;
        text-align: left;
    }

    &.align-start {
        align-items: flex-start;
        display: flex;

    }

    &.column-50 {
        display: block !important;
        margin: -80px 0 30px 0;

        @include respond-above(lg) {
            align-items: stretch;
            display: flex !important;
            flex-wrap: wrap;
        }

        .btn {
            width: 190px !important;
            display: block;
            margin: 0 auto;
            padding: 12px 14px;
        }

        .box-white-form-left {

            margin: 0 0 80px 0;
            padding: 0;
            width: auto;

            @include respond-above(sm) {
                margin: 16px 0 80px 16px;
            }

            @include respond-above(lg) {
                width: 50%;
                border-right: 1px solid $base-border-color;
                margin: 16px 0;
            }

            .box-white-form-content {
                padding: 0;

                ul {
                    margin: 0 0 40px 0;
                    text-align: left;

                    @include respond-above(sm) {
                        margin: 0 95px 40px 82px;
                    }
                }

                .btn {
                    margin: 0 auto 40px auto;
                }
            }
        }

        .box-white-form-right {
            margin: 0;
            width: auto;

            @include respond-above(sm) {
                margin: 16px 0;
            }

            @include respond-above(lg) {
                width: 50%;
            }

            .box-white-form-content {
                padding: 0;

                ul {
                    margin: 0 0 40px 0;
                    text-align: left;

                    @include respond-above(sm) {
                        margin: 0 95px 40px 82px;
                    }
                }
            }
        }

        h2 {
            font-size: $base-font-size + 6;
            line-height: $base-line-height + 4;
            font-weight: $font-weight-normal;
            margin: 0 0 40px 0;
            text-align: center;

            strong {
                font-weight: $font-weight-medium;
            }
        }
    }

    .box-white-form-left {
        margin: 0 0 20px 0;

        @include respond-above(sm) {
            width: calc(100% - 190px);
            margin: 0;
        }

        p {
            margin: 0;

            strong {
                display: block;
                font-size: $base-font-size + 2;
                line-height: $base-line-height;
                margin: 0 0 5px 0;
                font-weight: $font-weight-medium;
            }
        }
    }

    .box-white-form-right {
        width: 190px;
        margin: 0 auto;

        @include respond-above(sm) {
            margin: 0;
        }

        .btn {
            width: 100%;
            text-align: center;
            padding: 12px 14px;
        }
    }

    .box-white-form-content {
        @include respond-above(sm) {
            padding: 16px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                position: relative;
                padding: 0 0 0 28px;
                margin: 0 0 19px 0;

                &:last-child {
                    margin: 0;
                }

                &::before {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    content: "";
                    display: block;
                    left: 0;
                    top: 0;
                    background-image: url("../assets/check.svg");
                    background-repeat: no-repeat;
                    background-size: 20px 20px;
                    background-position: 0 0;
                }

                strong {
                    font-weight: 500;
                }
            }
        }
    }

    .box-white-form-cv-left {
        width: calc(100% - 70px);
        text-align: left;

        @include respond-above(md) {
            width: calc(100% - 135px);
        }

        strong {
            font-weight: $font-weight-medium;
        }

        .grey-color {
            color: $grey-color;
        }

        p {
            margin: 0 0 12px 0;

            &:last-child {
                margin: 0;
            }
        }


    }

    .box-white-form-cv-right {
        width: 70px;

        @include respond-above(md) {
            width: 135px;
        }

        .btn-text {
            margin-left: 0;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }

            @include respond-above(md) {
                margin-left: 15px;
                margin-bottom: 0;
            }
        }
    }
}
