.box-offer-tags {
    margin: 0 !important;
    padding: 0 !important;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    li {
        margin: 0 10px 10px 0;

        &:last-child {
            margin: 0 0 10px 0;
        }

        .btn-white {
            border-color: $base-border-color !important;
            color: $base-font-color !important;
            padding: 12px 20px;
            
            &:hover {
                background-color: $white-color;
                box-shadow: 0px 13px 33px rgb(0 0 0 / 14%);
                border-color: $white-color!important;
            }
        }
    }


}
