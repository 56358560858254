.article-list {
    padding: 30px 0 40px 0;

    .article {
        margin: 0 0 40px 0;
        
        @include respond-above(sm) {
            display: flex;
        }

        .article-image {
            margin: 0 0 20px 0;
            
            @include respond-above(sm) {
                width: 200px;
                margin: 0 20px 0 0;    
            }

            img {
                display: block;
                width: 100%;
                max-width: 100%;
            }
        }

        .article-info {
            @include respond-above(sm) {
                width: calc(100% - 220px);    
            }
            .article-date-time {
                font-size: $base-font-size - 2;
                line-height: $base-line-height;
                color: $grey-color;
                display: block;
                margin-bottom: 10px;
            }

            .article-title {
                a {
                    color: $base-font-color;

                    &:hover {
                        color: $base-color;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
